import { useState } from 'react';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import UserAvatar from './UserAvatar';
import { useAppSelector } from '../../store';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../lib/services/images';
import axios from '../../lib/api/axios';
import Duration from '../common/Duration';

const Wrapper = styled.div``;
const Identity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;

  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
    align-items: start;
  }
`;

const Name = styled.p`
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;

  span {
    margin-left: 24px;
    color: #98989c;
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Info = styled.div`
  margin-left: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Deleted = styled.span`
  font-size: 16px;
  color: #fff;
  border: 2px solid #b9375e;
  padding: 3px 8px;
  border-radius: 8px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const Admin = styled(Deleted)`
  border: 2px solid #3c8c8a;
`;

const Labels = styled.div`
  display: flex;
  width: 100%;
  margin-top: 14px;
  gap: 16px;
  align-items: start;

  [disabled] {
    cursor: default;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -o-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -moz-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -webkit-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}
  @-o-keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}
  @-moz-keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}
  @-webkit-keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 24px;
    width: auto;
  }
`;

const StatisticButton = styled.button`
  font-size: 16px;
  color: #fff;
  border: 2px solid #b9375e;
  padding: 10px 12px;
  border-radius: 8px;
  background-color: #3c8c8a;
  border: 2px solid #3c8c8a;
  cursor: pointer;

  :hover {
    background-color: #488584;
    border: 2px solid #488584;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    width: 100%;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

function UserInfo({ user }) {
  const auth = useAppSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState({
    startTime: null,
    endTime: null,
  });

  const getDownloadFile = async () => {
    return axios.get(`/task/export?userId=${user._id}&from=${duration.startTime}&to=${duration.endTime}`, {
      responseType: 'blob',
    });
  };

  const downloadFile = () => {
    setLoading(true);

    getDownloadFile()
      .then((response) => saveAs(response.data, 'report.xlsx'))
      .finally(() => setLoading(false));
  };

  function selectTime(time) {
    setDuration((prev) => {
      return {
        ...prev,
        ...time,
      };
    });
  }

  return (
    <Wrapper>
      <Identity>
        <UserAvatar
          user={user}
          auth={auth}
          src={user.avatar || DEFAULT_AVATAR_PLACEHOLDER}
        />
        <Info>
          <Wrap>
            <Name>
              {user.firstName} {user.lastName}
            </Name>
            <Tags>
              {user.isDeleted && <Deleted>Deleted</Deleted>}
              {user.isAdmin && <Admin>Manager</Admin>}
            </Tags>
          </Wrap>

          <Labels>
            <Duration startTime={duration.startTime} endTime={duration.endTime} selectTime={selectTime} />
            <StatisticButton onClick={downloadFile} type="button" disabled={loading}>
              Download statistics
            </StatisticButton>
          </Labels>
        </Info>
      </Identity>
    </Wrapper>
  );
}

export default UserInfo;
