import React from 'react';

function PdfIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <defs />
      <path d="M368 256h-64c-8.837 0-16 7.163-16 16v128c0 8.837 7.163 16 16 16h64c17.673 0 32-14.327 32-32v-96c0-17.673-14.327-32-32-32zm0 128h-48v-96h48v96zM512 288v-32h-80c-8.837 0-16 7.163-16 16v144h32v-64h64v-32h-64v-32h64z" />
      <path d="M32 464V48c0-8.837 7.163-16 16-16h240v64c0 17.673 14.327 32 32 32h64v48h32v-64c.025-4.253-1.645-8.341-4.64-11.36l-96-96C312.341 1.645 308.253-.024 304 0H48C21.49 0 0 21.491 0 48v416c0 26.51 21.49 48 48 48h112v-32H48c-8.836 0-16-7.163-16-16z" />
      <path d="M240 256h-64c-8.837 0-16 7.163-16 16v144h32v-48h48c17.673 0 32-14.327 32-32v-48c0-17.673-14.327-32-32-32zm0 80h-48v-48h48v48z" />
    </svg>
  );
}

export default PdfIcon;
