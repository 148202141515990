import React from 'react';

function UploadPhoto() {
  return (
    <svg
      width="48"
      height="34"
      viewBox="0 0 26 21"
      fill="#545970"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.4827 20H6.51611C2.61843 20 0 17.6229 0 14.0843V5.91672C0 2.37811 2.61843 0 6.51611 0H16.4839C20.3816 0 23 2.37811 23 5.91672V14.0843C23 17.6229 20.3816 20 16.4827 20Z"
        fill="#545970"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0154 5.675C15.0154 7.045 16.1304 8.16 17.5004 8.16C18.8714 8.16 19.9854 7.045 19.9854 5.675C19.9854 4.305 18.8714 3.189 17.5004 3.189C16.1304 3.189 15.0154 4.305 15.0154 5.675ZM3.59976 11.5503C3.3967 11.7473 3.25116 11.9721 3.15529 12.2118C2.86509 12.9369 3.01585 13.8084 3.32611 14.5265C3.69388 15.3814 4.39805 16.0272 5.28524 16.3096C5.67916 16.4359 6.09225 16.49 6.50447 16.49H13.8085C14.5353 16.49 15.1785 16.3209 15.7058 16.0037C16.0361 15.805 16.0945 15.3474 15.8496 15.0494C15.44 14.5526 15.0356 14.0541 14.6277 13.5513C13.8503 12.5891 13.3266 12.3103 12.7444 12.5552C12.5082 12.6562 12.2712 12.8088 12.0272 12.9691C11.377 13.3996 10.4733 13.9905 9.28281 13.3491C8.46907 12.9041 7.99671 12.1427 7.58557 11.48L7.57728 11.4667C7.54962 11.4223 7.52218 11.378 7.49483 11.3338C7.35503 11.1079 7.21734 10.8854 7.06136 10.6806C6.86788 10.427 6.14977 9.63389 5.22162 10.1986C4.62987 10.5542 4.13225 11.0353 3.59976 11.5503Z"
        fill="white"
      />
      <circle cx="22" cy="17" r="4" fill="white" />
      <rect x="21.5" y="15" width="1" height="4" rx="0.5" fill="#545970" />
      <rect
        x="20"
        y="17.5"
        width="1"
        height="4"
        rx="0.5"
        transform="rotate(-90 20 17.5)"
        fill="#545970"
      />
    </svg>
  );
}

export default UploadPhoto;
