/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import dayjs from 'dayjs';
import Pagination from '@material-ui/lab/Pagination';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store';
import { Task } from '../../store/slices/taskboardSlice';
import { openModal } from '../../store/slices/uiSlice';

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 8px 20px 20px;
  margin-bottom: 32px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 20px;
`;

const Filters = styled.div`
  display: flex;
  gap: 8px;
`;

const Filter = styled.button<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? '#fff' : '#545970')};
  cursor: pointer;
  margin-bottom: 16px;
  line-height: 1;
  font-size: 16px;
  background-color: #24252d;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;
`;

const Tabs = styled.div`
  display: flex;
  gap: 8px;
  border-bottom: 2px solid #24252d;
  margin: 0 -20px 16px;
  padding: 0 20px;
`;

const Tab = styled.button<{ isActive?: boolean }>`
  line-height: 1;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#fff' : '#545970')};
  position: relative;
  transition: color 0.2s;

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${({ isActive }) =>
      isActive ? '#3c8c8a' : 'transparent'};
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: background-color 0.2s;
  }
`;

const Overflow = styled.div`
  overflow-x: auto;
`;
const List = styled.ul``;

const TaskItem = styled.li`
  min-width: 800px;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  background: #3b4150;
  border-radius: 8px;
  margin-bottom: 8px;
  justify-content: space-around;
  cursor: pointer;
  transition: all 0.1s ease;

  :hover {
    background-color: #333845;
  }
`;

const ListHeader = styled.header`
  min-width: 800px;
  width: 100%;
  display: flex;
  background-color: #2a2d39;
  padding: 20px 20px;
  justify-content: space-around;

  p {
    color: #545970;
    font-weight: 600;
    line-height: 1;
    font-size: 14px;
    text-transform: uppercase;
  }
`;
const RequestId = styled.p`
  width: 15%;
`;
const Email = styled.p`
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CreatedAt = styled.p`
  text-align: left;
  padding-left: 8px;
  width: 20%;
`;
const ApprovedAt = styled.p`
  text-align: left;
  width: 20%;
`;
const TimeEstimate = styled.p`
  text-align: left;
  width: 10%;
`;

const Author = styled.p`
  width: 30%;
`;

const FinishedAt = styled.p`
  width: 25%;
`;

const Assigned = styled.p`
  width: 25%;
`;

const Status = styled.p`
  width: 20%;
`;

const NoTasks = styled.p`
  font-size: 18px;
  margin-top: 12px;
  text-transform: uppercase;
  font-weight: 600;
`;

const PaymentInfo = styled.p<{ isPaid?: boolean }>`
  width: 15%;
  text-align: right;

  span {
    display: inline-block;
    padding: 2px;
  }
`;

const StyledPagination = styled(Pagination)`
  padding-top: 24px;
  .MuiPagination-ul {
    justify-content: center;
  }
  .MuiPaginationItem-root {
    color: #fff;
  }
`;

const userStatusTitle = {
  0: 'To do',
  1: 'To do',
  2: 'Approved',
  3: 'Finished',
  4: 'In revision',
  5: 'After revision',
};

const statusTitle = {
  0: 'To do',
  1: 'In progress',
  2: 'Ready to send',
  3: 'Finished',
  4: 'In revision',
  5: 'After revision',
};

const renderStatus = (task, auth) => {
  if (task.isPending) {
    return 'Pending';
  }
  if (auth.isAdmin) {
    return statusTitle[task.status];
  }
  if (task.status === 1 && task.docUrl) {
    return 'In review';
  }

  return userStatusTitle[task.status];
};

const generateTableForUser = (
  tasks,
  userId = '60c64cf3699fe378fb2e33d3',
  isAdmin,
  tab,
  filter?
) => {
  let filteredTasks;

  if (!tasks) {
    return [];
  }

  if (!isAdmin) {
    filteredTasks = tasks.filter((task) => {
      const matchTab =
        tab === 'finished'
          ? task.status === 2 || task.status === 3
          : task.status < 2 || task.status === 5;

      return !(!task.executor || task.executor._id !== userId || !matchTab);
    });
  } else {
    filteredTasks = tasks.filter((task) => {
      const matchTab =
        tab === 'finished'
          ? task.status === 2 || task.status === 3
          : task.status < 2 || task.status === 5;

      const isOnWriting = task.executor && task.executor._id === userId;
      const isOnProofreading = task.proofreader && task.proofreader === userId;
      const matchFilter =
        filter === 'writing'
          ? isOnWriting
          : filter === 'proofreading'
          ? isOnProofreading
          : isOnWriting || isOnProofreading;

      return matchFilter && matchTab;
    });
  }

  return filteredTasks.sort(function (a, b) {
    return a.approvedAt > b.approvedAt
      ? -1
      : a.approvedAt < b.approvedAt
      ? 1
      : 0;
  });
};

const ITEM_PER_PAGE = 10;

const paginateItems = (page, items) => {
  if (items.length < ITEM_PER_PAGE) {
    return items;
  }
  const startIndex = (page - 1) * ITEM_PER_PAGE;
  const endIndex = page * ITEM_PER_PAGE;
  return items.slice(startIndex, endIndex);
};

function UserTasks({ user }) {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const tasks = useAppSelector((state) => state.taskboard.userTasks[user._id]);
  const [activeTab, setActiveTab] = useState('progress');
  const [activeFilter, setActiveFilter] = useState('writing');

  if (!user) {
    return null;
  }

  const items = generateTableForUser(
    tasks,
    user._id,
    user.isAdmin,
    activeTab,
    activeFilter
  );

  const pages = Math.ceil(items.length / ITEM_PER_PAGE);

  const paginatedItems = paginateItems(currentPage, items);

  const handleItemClick = (item) => () => {
    dispatch(openModal({ type: 'task', props: item }));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Wrapper>
      <Tabs>
        <Tab
          isActive={activeTab === 'progress'}
          onClick={() => {
            setActiveTab('progress');
          }}
        >
          In Progress
        </Tab>
        <Tab
          isActive={activeTab === 'finished'}
          onClick={() => {
            setActiveTab('finished');
          }}
        >
          Finished
        </Tab>
      </Tabs>
      {user.isAdmin && (
        <Filters>
          <Filter
            isActive={activeFilter === 'writing'}
            onClick={() => {
              setActiveFilter('writing');
            }}
          >
            Writing
          </Filter>
          <Filter
            isActive={activeFilter === 'proofreading'}
            onClick={() => {
              setActiveFilter('proofreading');
            }}
          >
            Proofreading
          </Filter>
        </Filters>
      )}

      <Overflow>
        <List>
          {items.length > 0 && (
            <ListHeader>
              {activeTab === 'progress' ? (
                <>
                  <RequestId>Request id</RequestId>
                  <Author>Author</Author>
                  <FinishedAt>
                    {activeFilter === 'writing' ? 'Created at' : 'Finished at'}
                  </FinishedAt>
                  <Assigned>Assigned at</Assigned>
                  <Status>Status</Status>
                </>
              ) : (
                <>
                  <RequestId>Request id</RequestId>
                  <Email>User email</Email>
                  <CreatedAt>Created at</CreatedAt>
                  <ApprovedAt>Finished at</ApprovedAt>
                  <TimeEstimate>Spent time</TimeEstimate>
                  <PaymentInfo>Payment</PaymentInfo>
                </>
              )}
            </ListHeader>
          )}
          {items.length === 0 ? (
            <NoTasks>No tasks found</NoTasks>
          ) : (
            paginatedItems.map((item) => {
              const date1 = dayjs(item.createdAt);
              const date2 = dayjs(item.approvedAt);
              const diff = date2.diff(date1, 'hour');
              return (
                <TaskItem key={item._id} onClick={handleItemClick(item)}>
                  {activeTab === 'progress' ? (
                    <>
                      <RequestId>{item.requestId}</RequestId>
                      <Author>
                        {user.firstName} {user.lastName}
                      </Author>
                      <FinishedAt>
                        {activeFilter === 'writing'
                          ? dayjs(item.createdAt).format('DD.MM.YY, HH:mm')
                          : dayjs(item.attachedAt).format('DD.MM.YY, HH:mm')}
                      </FinishedAt>
                      <Assigned>
                        {activeFilter === 'writing'
                          ? dayjs(item.assignedAt).format('DD.MM.YY, HH:mm')
                          : dayjs(item.proofReaderAt).format('DD.MM.YY, HH:mm')}
                      </Assigned>
                      <Status>
                        {renderStatus(item, user)}
                      </Status>
                    </>
                  ) : (
                    <>
                      <RequestId>{item.requestId}</RequestId>
                      <Email>{item.email}</Email>
                      <CreatedAt>
                        {dayjs(item.createdAt).format('DD.MM.YY, HH:mm')}
                      </CreatedAt>
                      <ApprovedAt>
                        {dayjs(item.approvedAt).format('DD.MM.YY, HH:mm')}
                      </ApprovedAt>
                      <TimeEstimate>
                        {diff > 0
                          ? `${diff} h`
                          : `${date2.diff(date1, 'minutes')} min`}
                      </TimeEstimate>
                      <PaymentInfo isPaid={item.paid}>
                        <span>
                          {user.isAdmin ? '---' : item.paid ? 'paid' : 'unpaid'}
                        </span>
                      </PaymentInfo>
                    </>
                  )}
                </TaskItem>
              );
            })
          )}
        </List>
      </Overflow>
      {items.length > 0 && (
        <StyledPagination
          color="secondary"
          count={pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
    </Wrapper>
  );
}

export default UserTasks;
