import React from 'react';

function Lock() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Light-Outline/Lock">
        <g id="Lock">
          <path
            id="Fill 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9229 10.2C16.5089 10.2 16.1729 9.86401 16.1729 9.45001V7.30301C16.1729 5.20701 14.4679 3.50201 12.3719 3.50201H12.3559C11.3429 3.50201 10.3939 3.89201 9.6769 4.60301C8.9549 5.31701 8.5559 6.27001 8.5519 7.28601V9.45001C8.5519 9.86401 8.2159 10.2 7.8019 10.2C7.3879 10.2 7.0519 9.86401 7.0519 9.45001V7.30301C7.0579 5.86301 7.6149 4.53401 8.6199 3.53801C9.6259 2.54101 10.9539 1.96401 12.3749 2.00201C15.2949 2.00201 17.6729 4.38001 17.6729 7.30301V9.45001C17.6729 9.86401 17.3369 10.2 16.9229 10.2Z"
            fill="white"
          />
          <path
            id="Fill 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.542 10.1288C6.864 10.1288 5.5 11.4928 5.5 13.1708V17.4598C5.5 19.1378 6.864 20.5018 8.542 20.5018H16.183C17.86 20.5018 19.225 19.1378 19.225 17.4598V13.1708C19.225 11.4928 17.86 10.1288 16.183 10.1288H8.542ZM16.183 22.0018H8.542C6.037 22.0018 4 19.9648 4 17.4598V13.1708C4 10.6658 6.037 8.62878 8.542 8.62878H16.183C18.688 8.62878 20.725 10.6658 20.725 13.1708V17.4598C20.725 19.9648 18.688 22.0018 16.183 22.0018Z"
            fill="white"
          />
          <path
            id="Fill 5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3623 17.1756C11.9483 17.1756 11.6123 16.8396 11.6123 16.4256V14.2046C11.6123 13.7906 11.9483 13.4546 12.3623 13.4546C12.7763 13.4546 13.1123 13.7906 13.1123 14.2046V16.4256C13.1123 16.8396 12.7763 17.1756 12.3623 17.1756Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}

export default Lock;
