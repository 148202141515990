import { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '../../common/forms/TextField';
import PhoneField from '../../common/forms/PhoneField';
import CreditCardField from '../../common/forms/CreditCardField';
import { useAppDispatch } from '../../../store';
import { editUserInfoAsync } from '../../../store/slices/usersSlice';

const Content = styled.div`
  padding: 28px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const Form = styled.form`
  min-width: 350px;
  > div {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 600px) {
    min-width: 300px;
  }
`;

const Actions = styled.div<{ isSuccess: boolean }>`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
  display: ${({ isSuccess }) => (isSuccess ? 'none' : 'flex')};
`;

const StyledButton = styled(Button)`
  width: 100px;

  :first-of-type {
    margin-right: 16px;
  }
`;

const Info = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #1f877f;
  border-radius: 8px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

const Group = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: start;
  justify-content: stretch;

  div {
    flex-grow: 1;
  }
`;

function UserEditForm({ user, onClose }) {
  const dispatch = useAppDispatch();

  const [fields, setFields] = useState({
    firstName: { value: user.firstName || '', error: '' },
    lastName: { value: user.lastName || '', error: '' },
    email: { value: user.email || '', error: '' },
    phone: { value: user.phone || '', error: '' },
    creditCard: { value: user.creditCard || '', error: '' },
    workFrom: { value: user.workFrom || '', error: '' },
    workTo: { value: user.workTo || '', error: '' },
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, creditCard, workFrom, workTo } = fields;
    setLoading(true);
    await dispatch(
      editUserInfoAsync({
        userId: user._id,
        values: {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phone: phone.value,
          creditCard: creditCard.value,
          workFrom: workFrom.value,
          workTo: workTo.value,
        },
      })
    );
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      onClose();
    }, 1200);
  };

  const handleChange = (target) => {
    setFields((state) => {
      const previous = state[target.name];
      return { ...state, [target.name]: { ...previous, value: target.value } };
    });
  };

  const handlePhoneChange = (value) => {
    setFields((state) => ({
      ...state,
      phone: { ...state.phone, value },
    }));
  };

  const handleCreditCardChange = (value) => {
    setFields((state) => ({
      ...state,
      creditCard: { ...state.creditCard, value },
    }));
  };

  return (
    <Content>
      <Form onSubmit={handleSubmit}>
        <TextField
          type="text"
          label="First name"
          name="firstName"
          onChange={handleChange}
          placeholder="Jhon"
          inputProps={{
            autoCorrect: 'off',
            autoComplete: 'off',
          }}
          initialValue={fields.firstName.value}
        />
        <TextField
          type="text"
          label="Last name"
          name="lastName"
          onChange={handleChange}
          placeholder="Doe"
          inputProps={{
            autoCorrect: 'off',
            autoComplete: 'off',
          }}
          initialValue={fields.lastName.value}
        />
        <TextField
          type="email"
          label="Email"
          name="email"
          onChange={handleChange}
          placeholder="example@gmail.com"
          inputProps={{
            autoComplete: 'off',
          }}
          initialValue={fields.email.value}
        />
        <PhoneField
          onChange={handlePhoneChange}
          initialValue={fields.phone.value}
        />
        <CreditCardField
          initialValue={fields.creditCard.value}
          onChange={handleCreditCardChange}
        />
        <Group>
          <TextField
            type="time"
            label="Work from"
            name="workFrom"
            onChange={handleChange}
            inputProps={{
              autoCorrect: 'off',
              autoComplete: 'off',
            }}
            initialValue={fields.workFrom.value}
          />
          <TextField
            type="time"
            label="Work to"
            name="workTo"
            onChange={handleChange}
            inputProps={{
              autoCorrect: 'off',
              autoComplete: 'off',
            }}
            initialValue={fields.workTo.value}
          />
        </Group>

        <Actions isSuccess={success}>
          <StyledButton
            type="button"
            color="primary"
            disableElevation
            variant="text"
            onClick={onClose}
            disabled={loading || success}
          >
            Cancel
          </StyledButton>
          <StyledButton
            type="submit"
            color="primary"
            disableElevation
            variant="contained"
            disabled={loading || success}
            endIcon={
              loading && (
                <CircularProgress size={20} style={{ color: '#fff' }} />
              )
            }
          >
            {loading ? 'Saving' : 'Save'}
          </StyledButton>
        </Actions>
        {success && (
          <Info
            initial={{ y: -5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
          >
            User profile has been changed <span>👌</span>
          </Info>
        )}
      </Form>
    </Content>
  );
}

export default UserEditForm;
