import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import PdfIcon from '../../common/icons/PdfIcon';
import { useAppDispatch } from '../../../store';
import { sendResponseAsync } from '../../../store/slices/taskboardSlice';
import CloseIcon from '../../common/icons/CloseIcon';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* backdrop-filter: blur(10px); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1000;
  padding: 2rem 1rem;
  perspective: 1000px; ;
`;

const Container = styled(motion.div)`
  position: relative;
  margin-bottom: 2rem;
  max-height: calc(100% - 64px);
  max-width: 940px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 32px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const Message = styled.textarea`
  width: 450px;
  outline: none;
  font-size: 1rem;
  resize: vertical;
  margin-bottom: 1rem;
  font-family: inherit;
  min-height: 150px;

  padding: 12px;
  font-size: 16px;
  display: block;
  border-radius: 8px;
  outline: none;
  border: 2px solid#73498a;
  caret-color: #73498a;
  background-color: #242424;
  color: #fff;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Button = styled.label`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  background-color: #73498a;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  border: 2px solid transparent;

  svg {
    margin-right: 8px;
  }

  span {
    margin-right: 8px;
  }

  :hover {
    background-color: #583869;
  }
`;

const Embed = styled.embed`
  width: 450px;
  height: 500px;
  margin-top: 8px;

  @media screen and (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
`;

const DocumentPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: rigth;
  position: relative;

  svg:hover {
    cursor: pointer;
  }
`;

const SendingMessage = styled.p`
  margin-bottom: 12px;
`;

const SendButton = styled.button`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  background-color: #73498a;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  margin-top: 12px;
  border: 2px solid transparent;

  span {
    margin-right: 8px;
  }

  :hover {
    background-color: #5b3b6c;
  }

  :disabled {
    cursor: not-allowed;
    background-color: #5b3b6c;
  }
`;

const LoaderWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.button`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #191a20;
  width: 32px;
  height: 32px;
  border-radius: 8px;

  :hover {
    transform: scale(1.05);
  }
`;

function SendResponseForm({ task, close }) {
  const [loading, setLoading] = useState(false);
  const [pdfPreview, setPdfPreview] = useState('');
  const dispatch = useAppDispatch();
  const [pdf, setPdf] = useState<File>(null);
  const [value, setValue] = useState(
    'Hello!\nThanks for reaching us on your plant issue. Our gardening Botanist has carefully examined your situation and prepared the PDF care guide.'
  );
  const handleClose = () => {
    close();
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const handleMessage = (e) => {
    setValue(e.target.value);
  };

  const resetFileInput = (e: any) => {
    if (e.target?.value) {
      e.target.value = null;
    }
  };

  const handleFiles = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    e.preventDefault();

    const { files } = e.target;

    if (!files || !files.length) {
      return;
    }

    if (files.length > 1) {
      return null;
    }

    const file = files[0];
    const pdfUrl = URL.createObjectURL(file);
    setPdfPreview(pdfUrl);
    setPdf(file);
  };

  const sendRequest = async () => {
    setLoading(true);
    await dispatch(
      sendResponseAsync({ taskId: task.requestId, pdf, comment: value })
    );
    setLoading(false);
    close();
  };

  const deletePdf = () => {
    setPdfPreview(null);
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(pdfPreview);
    };
  }, [pdfPreview]);

  return createPortal(
    <Backdrop onClick={handleClose}>
      <Container
        initial={{ y: -15 }}
        animate={{ y: 0 }}
        exit={{ y: 20 }}
        onClick={handlePropagation}
      >
        <Content>
          {task.comment ? (
            <SendingMessage>{task.comment}</SendingMessage>
          ) : (
            <Message
              required
              name="message"
              value={value}
              id="contact-us-message"
              onChange={handleMessage}
            />
          )}
          {!pdfPreview && (
            <>
              <Button htmlFor="upload-pdf">
                <PdfIcon />
                <span>Upload pdf</span>
              </Button>
              <input
                type="file"
                id="upload-pdf"
                accept=".pdf"
                onChange={handleFiles}
                onClick={resetFileInput}
                style={{ display: 'none' }}
              />
            </>
          )}
          {pdfPreview && (
            <DocumentPreview>
              <Tooltip
                key="dellete-pdf"
                placement="top"
                title="Delete pdf"
                style={{ zIndex: 10000 }}
              >
                <CloseButton type="button" onClick={deletePdf}>
                  <CloseIcon />
                </CloseButton>
              </Tooltip>
              <Embed
                type="application/pdf"
                src={pdfPreview}
                width="540"
                // height="200"
              />
            </DocumentPreview>
          )}
          {pdfPreview && (
            <SendButton disabled={loading} onClick={sendRequest}>
              <span>Send request</span>
              {loading && (
                <LoaderWrapper>
                  <CircularProgress style={{ color: '#fff' }} size={18} />
                </LoaderWrapper>
              )}
            </SendButton>
          )}
        </Content>
      </Container>
    </Backdrop>,
    document.body,
    'task-popup'
  );
}

export default SendResponseForm;
