import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../store';
import LogoutIcon from '../icons/LogoutIcon';
import { logout } from '../../../store/slices/authSlice';
import ArchiveIcon from '../icons/ArchiveIcon';
import homeIcon from '../../../static/home.svg';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../lib/services/images';

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  background-color: var(--black);
  border-radius: 40px;
  box-shadow: 0px 22.3363px 100.737px rgba(0, 0, 0, 0.408001),
    0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

type TabProps = {
  isActive?: boolean;
};

const tabShadow = `box-shadow: 0px 100px 451px rgba(0, 0, 0, 0.84), 0px 41.7776px 188.417px rgba(0, 0, 0, 0.555827),
0px 22.3363px 100.737px rgba(0, 0, 0, 0.408001), 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357), 0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);`;

const AvatarWrapper = styled.div<TabProps>`
  padding: 7px;
  border-radius: 50%;
  ${({ isActive }) => isActive && tabShadow}
`;

const Avatar = styled.img<TabProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Tab = styled.span<TabProps>`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  ${({ isActive }) => isActive && tabShadow}
`;

const LogoutButton = styled.button`
  margin-top: auto;
  width: 60px;
  height: 60px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

function Topbar() {
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  if (!auth) {
    return null;
  }
  return (
    <Wrapper>
      <Link to="/">
        <Tab isActive={location.pathname === '/'}>
          <img alt="home icon" src={homeIcon} />
        </Tab>
      </Link>
      <Link to={`/user/${auth._id}`}>
        <AvatarWrapper isActive={location.pathname.includes('/user')}>
          <Avatar src={auth.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
        </AvatarWrapper>
      </Link>
      {auth.isAdmin && (
        <Link to="/archive">
          <Tab isActive={location.pathname === '/archive'}>
            <ArchiveIcon />
          </Tab>
        </Link>
      )}
      <LogoutButton
        onClick={() => {
          dispatch(logout());
        }}
      >
        <LogoutIcon />
      </LogoutButton>
    </Wrapper>
  );
}

export default Topbar;
