import React from 'react';

function SwapIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9791 4.60132V17.2193"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.89999 8.7001C2.89999 8.7001 5.06899 4.6001 6.97799 4.6001C8.88599 4.6001 11.056 8.7001 11.056 8.7001"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9059 19.4276V6.80957"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.985 15.3286C20.985 15.3286 18.815 19.4286 16.907 19.4286C14.999 19.4286 12.829 15.3286 12.829 15.3286"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SwapIcon;
