function HomeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Light-Outline/Home">
        <g id="Home">
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="2"
            y="1"
            width="21"
            height="22"
          >
            <path
              id="Clip 2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 1.00024H22.4998V22.5052H2V1.00024Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <path
              id="Fill 1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7168 15.2914C14.9208 15.2914 15.9008 16.2644 15.9008 17.4604V20.5364C15.9008 20.7934 16.1068 20.9994 16.3708 21.0054H18.2768C19.7788 21.0054 20.9998 19.7994 20.9998 18.3174V9.59337C20.9928 9.08337 20.7498 8.60337 20.3328 8.28437L13.7398 3.02637C12.8548 2.32537 11.6168 2.32537 10.7288 3.02837L4.1808 8.28237C3.7478 8.61137 3.5048 9.09137 3.4998 9.61037V18.3174C3.4998 19.7994 4.7208 21.0054 6.2228 21.0054H8.1468C8.4178 21.0054 8.6378 20.7904 8.6378 20.5264C8.6378 20.4684 8.6448 20.4104 8.6568 20.3554V17.4604C8.6568 16.2714 9.6308 15.2994 10.8258 15.2914H13.7168ZM18.2768 22.5054H16.3528C15.2508 22.4794 14.4008 21.6144 14.4008 20.5364V17.4604C14.4008 17.0914 14.0938 16.7914 13.7168 16.7914H10.8308C10.4618 16.7934 10.1568 17.0944 10.1568 17.4604V20.5264C10.1568 20.6014 10.1468 20.6734 10.1258 20.7414C10.0178 21.7314 9.1718 22.5054 8.1468 22.5054H6.2228C3.8938 22.5054 1.9998 20.6264 1.9998 18.3174V9.60337C2.0098 8.60937 2.4678 7.69937 3.2588 7.10037L9.7938 1.85537C11.2328 0.715372 13.2378 0.715372 14.6738 1.85337L21.2558 7.10337C22.0288 7.69237 22.4868 8.60037 22.4998 9.58237V18.3174C22.4998 20.6264 20.6058 22.5054 18.2768 22.5054Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HomeIcon;
