/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { FieldWrapper, Label, Border, ErrorMessage } from './TextField';

const StyledCreditCardInput = styled(InputMask)`
  display: block;
  font-size: 16px;
  width: 100%;
  padding: 6px 6px 6px 0px;
  color: #d4d4d4;
  outline: none;
  border: none;
  background: transparent;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #d4d4d4;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

function CreditCardField({ name = 'creditCard', initialValue = '', onChange }) {
  const [card, setCard] = useState(initialValue);
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (event) => {
    setCard(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value, selection } = newState;
    let cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !card.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  return (
    <FieldWrapper>
      <Label htmlFor="credit-card" active={focus || Boolean(error)}>
        Credit card
      </Label>
      <StyledCreditCardInput
        id="credit-card"
        type="tel"
        name={name}
        mask="9999 9999 9999 9999"
        placeholder="1259 2353 2451 9053"
        maskChar={null}
        value={card}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        beforeMaskedValueChange={beforeMaskedValueChange}
      />
      <Border className="divider" isFocused={focus} />
      {error && <ErrorMessage>Incorrect card format</ErrorMessage>}
    </FieldWrapper>
  );
}

export default React.memo(CreditCardField);
