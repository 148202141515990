import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { useAppDispatch } from '../../../store';
import { openModal } from '../../../store/slices/uiSlice';
import PlusIcon from '../../common/icons/PlusIcon';

const IconButton = styled.button`
  border-radius: 6px;
  margin-left: 12px;
  background-color: #22232b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  transition: 0.2s ease;

  :hover {
    background-color: #121216;
  }
`;

function NewTaskButton() {
  const dispatch = useAppDispatch();
  const handleItemClick = () => {
    dispatch(openModal({ type: 'create-task' }));
  };
  return (
    <Tooltip placement="top" title="Create new task">
      <IconButton type="button" onClick={handleItemClick}>
        <PlusIcon />
      </IconButton>
    </Tooltip>
  );
}

export default NewTaskButton;
