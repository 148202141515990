import { useCallback, useState } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import AutocompleteField from '../../common/forms/AutocompleteField';
import { useAppSelector } from '../../../store';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 10000;
  padding: 2rem 1rem;
  perspective: 1000px; ;
`;

const Container = styled(motion.div)`
  position: relative;
  margin-bottom: 2rem;
  max-height: calc(100% - 64px);
  max-width: 940px;
  max-width: min(100%, 940px);
`;

const Content = styled.div`
  position: relative;
  padding: 32px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const Input = styled.input`
  width: 100%;
  outline: none;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: inherit;

  padding: 12px;
  font-size: 16px;
  display: block;
  border-radius: 8px;
  border: 2px solid#b9375e;
  caret-color: #b9375e;
  background-color: #242424;
  color: #fff;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Field = styled.div`
  margin-top: 15px;
  position: relative;
`;

const Message = styled.p`
  margin-bottom: 24px;
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  > button:last-of-type {
    margin-left: 16px;
  }
`;

const Form = styled.form``;

const diseases = [
  'Overwatering',
  'Underwatering',
  'Temperature stress',
  'Fungal infection',
  'Lack of humidity',
  'Pests',
  'Root rot',
  'Nutrient deficiency',
  'Lack of drainage',
  'Lack of light',
  'Tap water',
  'Transplanting shock',
  'Bacterial',
  'Ageing',
  'Cold tempertatures',
  'Overfertilizing',
  'Acclimatization',
  'Improper pot size',
  'Physical damage',
  'End of the season',
  'Compacted soil',
  'Rootbound',
  'Wrong soil pH',
  'High humidity',
  'Waterlogged soil',
  'Virus',
  'Other',
];

const locations = [
  'Indoor',
  'Outdoor',
  'Potted outdoors',
];

function AttachDocumentModal({ initialValue, close, saveDocument, saveFullDocument = undefined, extraValues = undefined }) {
  const auth = useAppSelector((state) => state.auth);
  
  const [value, setValue] = useState({
    document: initialValue || '',
    plant: extraValues?.plant || '',
    disease: extraValues?.disease || '',
    location: extraValues?.location || '',
  });

  const [plantAutocomplete, setPlantAutocomplete] = useState([]);
  
  const handleClose = () => {
    close();
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const handleChange = (name, value) => {
    if (name) {
      setValue((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const handleSubmit = () => {
    if (saveFullDocument) {
      saveFullDocument(value);
      return;
    }

    saveDocument(value.document);
  };

  const handlePlantAutocomplete = useCallback(debounce((value)=>{
    if (value.length > 2) {
      fetch(`https://strp.myplantin.com/api/plants?fields[0]=latin&pagination[withCount]=false&filters[latin][$containsi]=${encodeURIComponent(value)}`)
        .then((res) => res.json())
        .then((data) => {
          setPlantAutocomplete(data.data.map((plant) => plant.latin).filter((plant) => plant?.trim()));
        });
    }
  }, 500), []);

  return createPortal(
    <Backdrop>
      <Container
        initial={{ y: -15 }}
        animate={{ y: 0 }}
        onClick={handlePropagation}
      >
        <Content>
          <Message>Please, paste valid google doc url here 🙏</Message>
          <Form onSubmit={handleSubmit}>
            <Input
              required={!auth.isAdmin}
              name="document"
              value={value.document || ''}
              placeholder="Paste google doc url here"
              id="contact-us-message"
              onChange={(e) => handleChange('document', e.target.value)}
            />

            <Field>
              <AutocompleteField
                label="Plant"
                name="plant"
                onChange={(value) => {
                  handlePlantAutocomplete(value);
                  handleChange('plant', value);
                }}
                placeholder="Plant"
                inputProps={{
                  autoComplete: 'off',
                  autoCorrect: 'off',
                }}
                options={plantAutocomplete}
                initialValue={value.plant || ''}
                freeSolo
              />
            </Field>
            <Field>
              <AutocompleteField
                multiple
                label="Disease"
                name="disease"
                onChange={(value) => handleChange('disease', value)}
                placeholder="Disease"
                inputProps={{
                  autoComplete: 'off',
                  autoCorrect: 'off',
                }}
                options={diseases}
                initialValue={value.disease || ''}
              />
            </Field>
            <Field>
              <AutocompleteField
                label="Location"
                name="location"
                onChange={(value) => handleChange('location', value)}
                placeholder="Location"
                inputProps={{
                  autoComplete: 'off',
                  autoCorrect: 'off',
                }}
                options={locations}
                initialValue={value.location || ''}
              />
            </Field>

            <Actions>
              <Button
                variant="text"
                color="primary"
                disableElevation
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
              >
                Done
              </Button>
            </Actions>
          </Form>
        </Content>
      </Container>
    </Backdrop>,
    document.body,
    'task-popup'
  );
}

export default AttachDocumentModal;
