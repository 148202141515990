import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '../../icons/CloseIcon';
import UploadPhotoIcon from '../../icons/UploadPhotoIcon';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  color: var(--accent);
`;

const UploadFrames = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 120px;
  grid-gap: 12px;
`;

const UploadFrameWrapper = styled.div`
  background: #242424;
  border-radius: 12px;
  position: relative;
  height: 100%;
  width: 100%;
`;

const UploadFrame = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const UploadInput = styled.input`
  display: none;
`;

const UploadedImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

const ClearIconButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 24px;
  height: 24px;
  background: red;
  border-radius: 50%;
  cursor: pointer;
`;

const imageMap = {};

const fileTypes = [
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
];

function validFileType(files) {
  if (!files || !files[0]) return false;
  return fileTypes.includes(files[0].type);
}

function ImageFrames({ setImages }) {
  const [frames, setFrames] = useState([
    { isPreview: false, imageUrl: '' },
    { isPreview: false, imageUrl: '' },
    { isPreview: false, imageUrl: '' },
  ]);

  const toogleFramePreview = (index, imageUrl = '') => {
    setFrames((prev) => {
      return prev.map((frame, i) => {
        const updatedFrame = {
          ...frame,
          imageUrl,
          isLoading: false,
          isPreview: !frame.isPreview,
        };
        return i === index ? updatedFrame : frame;
      });
    });
  };

  const handleClear = (index) => (e) => {
    e.stopPropagation();
    URL.revokeObjectURL(imageMap[index]);
    delete imageMap[index];
    setImages((prev) => ({ ...prev, ...imageMap }));
    toogleFramePreview(index);
  };

  const handleUploadChange = (index) => (e) => {
    e.preventDefault();

    const { files } = e.target;

    if (!files.length) {
      return null;
    }

    if (!validFileType(files)) {
      return null;
    }

    const file = files[0];
    imageMap[index] = file;
    setImages(imageMap);
    toogleFramePreview(index, URL.createObjectURL(file));
  };

  const resetFileInput = (e) => {
    e.target.value = null;
  };

  return (
    <Wrapper>
      <Label>Photos*</Label>
      <UploadFrames>
        {frames.map((frame, index) => {
          const { isPreview, imageUrl } = frame;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <UploadFrameWrapper key={index}>
              {isPreview && (
                <ClearIconButton onClick={handleClear(index)}>
                  <CloseIcon fill="#fff" />
                </ClearIconButton>
              )}
              {isPreview && <UploadedImage src={imageUrl} />}
              {!isPreview && (
                <UploadFrame htmlFor={`upload-task-image-${index}`}>
                  <UploadPhotoIcon />
                  <UploadInput
                    type="file"
                    accept="image/*"
                    onClick={resetFileInput}
                    onChange={handleUploadChange(index)}
                    id={`upload-task-image-${index}`}
                  />
                </UploadFrame>
              )}
            </UploadFrameWrapper>
          );
        })}
      </UploadFrames>
    </Wrapper>
  );
}

export default React.memo(ImageFrames);
