import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useUrlParamsFilter = <T>(initialState: T) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const initialFiltersState = {
    ...initialState,
    ...Object.fromEntries(urlParams),
  };

  const [filterState, setFilterState] = useState<T>(initialFiltersState);

  const onParamsChange = ({ name, value }) => {
    const params = new URLSearchParams(location.search);
    if (value) {
      params.set(name, value);
    } else {
      params.delete(name);
    }

    history.push({ search: params.toString() });
  };

  const handleFiltersChange = ({ name, value }) => {
    setFilterState({ ...filterState, [name]: value });

    onParamsChange({ name, value });
  };

  return {
    filterState,
    setFilterState,
    handleFiltersChange,
  };
};
