import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import styled from 'styled-components';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../lib/services/images';
import { useAppDispatch, useAppSelector } from '../../../store';
import { assignProofReaderAsync } from '../../../store/slices/taskboardSlice';

const ButtonWrapper = styled.div`
  margin-top: auto;
  position: relative;
`;
const Button = styled.button<{ isOpen?: boolean }>`
  width: 100%;
  margin-top: 24px;
  text-align: center;
  padding: 12px 32px;
  background-color: ${({ isOpen }) => (isOpen ? '#6a2b59' : '#7a3667')};
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;

  transition: all 0.2s ease;
  transition: background-color 0.2s ease;

  :hover {
    background-color: #6a2b59;
  }
`;

const UserSelector = styled(motion.ul)`
  position: absolute;
  padding: 12px;
  left: 0;
  width: 100%;
  top: 120%;

  border-radius: 8px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 8px;
  min-height: 220px;
  max-height: 220px;
  overflow: auto;
`;

const UserItem = styled.li`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  :hover {
    background-color: #232530;
  }
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
`;

const Name = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const TakeTaskButton = styled(Button)`
  border: 2px solid #7a3667;
  background: transparent !important;
  transition: all 0.2s ease;
  margin-top: 16px;

  :hover {
    border: 2px solid #6a2b59;
  }
`;

function AssignProofreading({ taskId }) {
  const [open, setopen] = useState(false);
  const auth = useAppSelector((state) => state.auth);
  const users = useAppSelector((state) => state.users);
  const admins = users.filter(({ isAdmin }) => isAdmin);
  const dispatch = useAppDispatch();

  const assignProofReader = (userId) => async () => {
    await dispatch(
      assignProofReaderAsync({
        taskId,
        userId,
      })
    );
  };

  const toogleSelector = () => {
    setopen((prev) => !prev);
  };

  return (
    <>
      <ButtonWrapper>
        <Button isOpen={open} onClick={toogleSelector}>
          Assign proofreading
        </Button>
        <AnimatePresence>
          {open && (
            <UserSelector
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              {admins.map((user) => {
                if (user.isDeleted) {
                  return null;
                }
                return (
                  <UserItem
                    key={user._id}
                    onClick={assignProofReader(user._id)}
                  >
                    <Avatar src={user.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
                    <Name>
                      {user.firstName} {user.lastName}
                    </Name>
                  </UserItem>
                );
              })}
            </UserSelector>
          )}
        </AnimatePresence>
      </ButtonWrapper>
      <TakeTaskButton
        onClick={() => {
          assignProofReader(auth._id)();
        }}
      >
        Take proofreading task
      </TakeTaskButton>
    </>
  );
}

export default AssignProofreading;
