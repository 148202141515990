import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import styled from 'styled-components';
import { FetchArchiveTasksPayload, Task } from '../../store/slices/taskboardSlice';
import { User } from '../../store/slices/usersSlice';
import { FilterState } from './types';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../lib/services/images';
import { useAppDispatch } from '../../store';
import { openModal } from '../../store/slices/uiSlice';

const Wrapper = styled.div`
  padding-bottom: 240px;
`;

const AmountWrapper = styled.div`
  text-align: right;
`;

const AmountInfo = styled.div`
  color: #fff;
  text-align: left;
  background-color: #3b4150;
  border-radius: 12px;
  padding: 12px 16px;
  display: inline-block;
  margin-bottom: 24px;

  p:first-child {
    margin-bottom: 8px;
  }

  span {
    font-weight: 600;
    color: var(--primary);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Container = styled.div`
  overflow-x: auto;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-wrap: nowrap;
    margin-right: -16px;
    margin-left: -16px;
    padding-left: 16px;
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
const InnerContainer = styled.div`
  min-width: 1000px;
`;
const Util = styled.div`
  width: 16px;
  flex-shrink: 0;
`;
const List = styled.ul`
  margin-bottom: 48px;

  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px 20px;
  background: #3b4150;
  border-radius: 8px;
  cursor: pointer;
`;

const RequestId = styled.p`
  width: 10%;
`;
const Customer = styled.p`
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Executor = styled.div`
  display: flex;
  width: 25%;
  align-items: center;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const Created = styled.p`
  width: 15%;
  text-align: left;
`;
const Sended = styled.p`
  width: 15%;
  text-align: left;
`;
const PaymentInfo = styled.p<{ isPaid?: boolean; isAdmin?: boolean }>`
  width: 5%;
  text-align: center;
  background-color: ${({ isAdmin, isPaid }) =>
    // eslint-disable-next-line no-nested-ternary
    isAdmin ? 'var(--black)' : isPaid ? '#1f877f' : 'var(--error)'};
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;

  span {
    display: inline-block;
    padding: 4px;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 0px 20px;
  align-items: center;
  margin-bottom: 24px;
  color: #545970;
  font-weight: 600;
  line-height: 1;
  font-size: 14px;
  text-transform: uppercase;

  button {
    color: #545970;
    font-weight: 600;
    line-height: 1;
    font-size: 14px;
    margin: 0;
    text-align: left;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;
  }

  .id {
    width: 10%;
  }

  .email {
    width: 25%;
  }

  .executor {
    width: 25%;
  }

  .proofreader {
    width: 25%;
  }

  .created {
    width: 15%;
    justify-content: flex-start;
  }

  .sended {
    width: 15%;
    justify-content: flex-start;
  }

  .payment {
    width: 5%;
    text-align: center;
  }
`;

interface TableProps {
  tasks: Task[];
  users: User[];
  filterState: FetchArchiveTasksPayload;
}

const generateTable = (tasks, filterState) => {
  let result = tasks;
  if (filterState.executor) {
    result = tasks.filter(
      (task) => task.executor && task.executor._id === filterState.executor
    );
  }

  if (filterState.startTime && filterState.endTime) {
    result = result.filter((task) => {
      const sendedAtInMs = new Date(task.sendedAt).getTime();
      if (
        sendedAtInMs >= filterState.startTime &&
        sendedAtInMs <= filterState.endTime
      ) {
        return true;
      }
      return false;
    });
  }

  return result;
};

const sortItemsByCreated = (items, order) => {
  if (order === 'desc') {
    items.sort(function (a, b) {
      // eslint-disable-next-line no-nested-ternary
      return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
    });
  } else {
    items.sort(function (a, b) {
      // eslint-disable-next-line no-nested-ternary
      return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
    });
  }
};

const sortItemsBySended = (items, order) => {
  if (order === 'desc') {
    items.sort(function (a, b) {
      // eslint-disable-next-line no-nested-ternary
      return a.sendedAt > b.sendedAt ? -1 : a.sendedAt < b.sendedAt ? 1 : 0;
    });
  } else {
    items.sort(function (a, b) {
      // eslint-disable-next-line no-nested-ternary
      return a.sendedAt < b.sendedAt ? -1 : a.sendedAt > b.sendedAt ? 1 : 0;
    });
  }
};

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: center;
  }

  .MuiPaginationItem-root {
    color: #fff;
  }
`;

const NoTasks = styled.p`
  font-size: 18px;
  padding-top: 64px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`;

const ITEM_PER_PAGE = 20;

const paginateItems = (page, items) => {
  if (items.length < ITEM_PER_PAGE) {
    return items;
  }
  const startIndex = (page - 1) * ITEM_PER_PAGE;
  const endIndex = page * ITEM_PER_PAGE;
  return items.slice(startIndex, endIndex);
};

const Table: React.FC<TableProps> = ({ tasks, users, filterState }) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [createdOrder, setCreatedOrder] = useState('desc');
  const [sendedOrder, setSendedOrder] = useState('desc');
  const [activeOrder, setActiveOrder] = useState('sended');
  const items = [...tasks];
  const filteredItems = generateTable(items, filterState);
  const changeCreatedOrder = () => {
    setActiveOrder('created');
    setCreatedOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const changeSendedOrder = () => {
    setActiveOrder('sended');
    setSendedOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  if (activeOrder === 'created') {
    sortItemsByCreated(items, createdOrder);
  } else {
    sortItemsBySended(items, sendedOrder);
  }

  const pages = Math.ceil(filteredItems.length / ITEM_PER_PAGE);

  const paginatedItems = paginateItems(currentPage, filteredItems);

  const handleItemClick = (task) => () => {
    dispatch(openModal({ type: 'task', props: task }));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterState.executor]);
  const allAmount = items.length;
  const amountPerSelectedPeriod = filteredItems.length;

  return (
    <Wrapper>
      <AmountWrapper>
        <AmountInfo>
          <p>
            Finished tasks: <span>{allAmount}</span>
          </p>
          <p>
            Per period: <span>{amountPerSelectedPeriod}</span>
          </p>
        </AmountInfo>
      </AmountWrapper>
      <Container>
        <InnerContainer>
          <Header>
            <p className="id">Request id</p>
            <p className="email">Customer</p>
            <p className="executor">Author</p>
            <p className="proofreader">Proofreader</p>
            <button
              type="button"
              className="created"
              onClick={changeCreatedOrder}
            >
              <span className="created">Created</span>
              {activeOrder === 'created' && (
                <span className="icon">
                  {createdOrder === 'desc' ? (
                    <KeyboardArrowDownRoundedIcon />
                  ) : (
                    <KeyboardArrowUpRoundedIcon />
                  )}
                </span>
              )}
            </button>
            <button
              className="sended"
              type="button"
              onClick={changeSendedOrder}
            >
              <span>Sent</span>
              {activeOrder === 'sended' && (
                <span className="icon">
                  {sendedOrder === 'desc' ? (
                    <KeyboardArrowDownRoundedIcon />
                  ) : (
                    <KeyboardArrowUpRoundedIcon />
                  )}
                </span>
              )}
            </button>
            <p className="payment">Payment</p>
          </Header>
          {paginatedItems.length > 0 ? (
            <List>
              {paginatedItems.map((item) => {
                const {
                  requestId,
                  proofreader,
                  email,
                  createdAt,
                  sendedAt,
                  executor,
                } = item;
                const user = users.find((u) => u._id === executor?._id);
                return (
                  <Item key={item.requestId} onClick={handleItemClick(item)}>
                    <RequestId>{requestId}</RequestId>
                    <Customer>{email}</Customer>
                    <Executor>
                      <img
                        className="avatar"
                        alt="user avatar"
                        src={user?.avatar || DEFAULT_AVATAR_PLACEHOLDER}
                      />
                      <span>
                        {user?.firstName} {user?.lastName}
                      </span>
                    </Executor>
                    <Executor>
                      <img
                        className="avatar"
                        alt="proofreader avatar"
                        src={proofreader?.avatar || DEFAULT_AVATAR_PLACEHOLDER}
                      />
                      <span>
                        {proofreader?.firstName} {proofreader?.lastName}
                      </span>
                    </Executor>
                    <Created>
                      {dayjs(createdAt).format('DD.MM.YY, HH:mm')}
                    </Created>
                    <Sended>{dayjs(sendedAt).format('DD.MM.YY, HH:mm')}</Sended>
                    <PaymentInfo
                      isAdmin={item.executor?.isAdmin}
                      isPaid={item.paid}
                    >
                      {item.executor?.isAdmin ? (
                        <span className="admin">---</span>
                      ) : (
                        <span>{item.paid ? 'paid' : 'unpaid'}</span>
                      )}
                    </PaymentInfo>
                  </Item>
                );
              })}
            </List>
          ) : (
            <NoTasks>No finished tasks</NoTasks>
          )}
        </InnerContainer>
        <Util />
      </Container>
      {filteredItems.length > 0 && (
        <StyledPagination
          color="secondary"
          count={pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
    </Wrapper>
  );
};

export default Table;
