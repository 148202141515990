import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import { Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import Column from './column/Column';
import TaskPopupManager from './popup/TaskPopupManager';
import { fetchAllTasksAsync } from '../../store/slices/taskboardSlice';
import UserTaskBoard from './UserTaskBoard';

const Wrapper = styled(motion.div)`
  padding-bottom: 100px;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media screen and (max-width: 600px) {
    padding-bottom: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const Util = styled.div`
  width: 1px;
  flex-shrink: 0;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 320px;
  margin-right: 24px;
  height: 800px;
  flex-shrink: 0;
  border-radius: 20px;
`;

function TaskBoard() {
  const auth = useAppSelector((state) => state.auth);
  const taskboard = useAppSelector((state) => state.taskboard);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loadTaks = async () => {
      await dispatch(fetchAllTasksAsync());
    };
    if (auth && auth._id) {
      loadTaks();
    }
  }, [auth, dispatch]);

  if (!auth) {
    return <Redirect to="/login" />;
  }

  // USER BOARD
  if (!auth.isAdmin) {
    return <UserTaskBoard />;
  }

  if (Object.keys(taskboard.tasks).length === 0) {
    return (
      <ColumnsWrapper>
        <StyledSkeleton variant="rect" />
        <StyledSkeleton variant="rect" />
        <StyledSkeleton variant="rect" />
      </ColumnsWrapper>
    );
  }

  // ADMIN BOARD
  return (
    <>
      <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <ColumnsWrapper>
          {[
            { id: 'to-do', title: 'TO DO' },
            { id: 'in-progress', title: 'IN PROGRESS' },
            { id: 'in-review', title: 'In Review' },
            { id: 'ready-to-send', title: 'READY TO SEND' },
            { id: 'pending', title: 'Pending' },
          ].map((column) => {
            const { id, title } = column;
            return (
              <Column
                type="admin"
                auth={auth}
                key={id}
                title={title}
                columnId={id}
                tasks={taskboard.tasks}
              />
            );
          })}
          <Util />
        </ColumnsWrapper>
      </Wrapper>
      <AnimatePresence>
        <TaskPopupManager />
      </AnimatePresence>
    </>
  );
}

export default TaskBoard;
