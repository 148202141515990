import { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useAppDispatch } from '../../store';
import { changeAvatarAsync } from '../../store/slices/usersSlice';

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1100;
  padding: 2rem 1rem;
  perspective: 1000px; ;
`;

const Container = styled(motion.div)`
  margin-bottom: 2rem;
  max-height: calc(100% - 64px);
  max-width: 940px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Content = styled.div`
  padding: 32px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);

  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;

const Image = styled.img`
  margin-bottom: 48px;
  max-width: 500px;
  border-radius: 16px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 12px;
  }
`;

function UploadAvatarPopup({ file, userId, onClose }) {
  const imageUrl = URL.createObjectURL(file);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const handleUpload = async () => {
    setLoading(false);
    await dispatch(changeAvatarAsync({ userId, file }));
    setLoading(true);
    onClose();
  };

  return (
    <Backdrop
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container
        initial={{ y: -15 }}
        animate={{ y: 0 }}
        exit={{ y: -15 }}
        onClick={handlePropagation}
      >
        <Content>
          <Image src={imageUrl} />
          <Actions>
            <Button
              variant="text"
              size="medium"
              color="primary"
              disableElevation
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              disableElevation
              type="button"
              endIcon={
                loading && (
                  <CircularProgress size={20} style={{ color: '#fff' }} />
                )
              }
              onClick={handleUpload}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
          </Actions>
        </Content>
      </Container>
    </Backdrop>
  );
}

export default UploadAvatarPopup;
