import axios from 'axios';

const instance = axios.create({
  baseURL: '/api',
});
instance.defaults.headers.post['Content-Type'] = 'application/json';

export const saveUserTokenToAxios = (token) => {
  instance.defaults.headers.common.Authorization = token;
};

export const removeUserTokenFromAxios = () => {
  delete instance.defaults.headers.common.Authorization;
};
// =============================================================================

export default instance;
