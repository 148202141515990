import React, { useState } from 'react';
import styled from 'styled-components';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

export const FieldWrapper = styled.div`
  position: relative;
  :hover {
    .divider {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const Label = styled.label<{
  active: boolean;
  isTransformed?: boolean;
}>`
  position: relative;
  font-weight: 500;
  line-height: 1;
  font-size: 14px;
  transition: all 0.1s ease-in-out;
  color: ${({ active }) => (active ? '#b9375e' : '#545970')};
  display: inline-flex;
  align-items: center;

  > span:first-child {
    margin-right: 8px;
  }
`;

export const Border = styled.div<{ isFocused: boolean }>`
  height: 1px;
  width: 100%;
  position: relative;
  background-color: #545970;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #b9375e;
    transition: transform 0.1s ease-in-out;
    transform: ${({ isFocused }) => (isFocused ? 'scaleX(1)' : 'scaleX(0)')};
  }
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 4px 4px 4px 0px;
  color: #d4d4d4;
  outline: none;
  border: none;
  background: transparent;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #d4d4d4;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  padding-top: 4px;
  color: #b9375e;
  font-weight: 600;
`;

interface TextFieldProps {
  type: string;
  name?: string;
  error?: string;
  label?: string;
  inputProps?: any;
  initialValue?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (value: any) => void;
}

const TextField: React.FC<TextFieldProps> = ({
  name,
  type,
  error,
  label,
  required = false,
  placeholder,
  inputProps,
  initialValue = '',
  onChange,
}) => {
  const [value, setPhone] = useState(initialValue);
  const [focus, setFocus] = useState(false);
  const handleChange = (event) => {
    setPhone(event.target.value);
    if (onChange) {
      onChange(event.target);
    }
  };
  const handleFocus = () => {
    setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };
  return (
    <FieldWrapper>
      {label && (
        <Label htmlFor={name} active={focus || Boolean(error)}>
          <span>
            {label}
            {required && '*'}
          </span>
          {error && (
            <span>
              <WarningRoundedIcon
                fontSize="small"
                style={{ color: 'b9375e' }}
              />
            </span>
          )}
        </Label>
      )}
      <StyledInput
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...inputProps}
      />
      <Border className="divider" isFocused={focus} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FieldWrapper>
  );
};

export default React.memo(TextField);
