import jwt_decode from 'jwt-decode';
import {
  createSlice,
  createAsyncThunk,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios, {
  removeUserTokenFromAxios,
  saveUserTokenToAxios,
} from '../../lib/api/axios';

export interface AuthState {
  _id: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
}

const initialState: AuthState | null = {
  _id: '',
  isAdmin: false,
  isSuperAdmin: false,
  firstName: '',
  lastName: '',
  email: '',
  avatar: '',
};

type Logout = null;

const LOGOUT: CaseReducer<AuthState, PayloadAction<Logout>> = () => {
  localStorage.removeItem('token');
  removeUserTokenFromAxios();
  return null;
};

interface ErrorResponse {
  status?: number;
  message: string;
}

type LoginReturnData = AuthState;

type LoginPayload = {
  email: string;
  password: string;
};

interface ThunkApi {
  rejectValue: ErrorResponse;
}

export const initAuthAsync = createAsyncThunk<
  AuthState | null,
  undefined,
  ThunkApi
>('auth/init-auth', async (payload, { rejectWithValue }) => {
  try {
    const cachedToken = localStorage.getItem('token');

    if (cachedToken) {
      const response = await axios({
        method: 'get',
        url: '/user/info',
        headers: {
          Authorization: cachedToken,
        },
      });
      saveUserTokenToAxios(cachedToken);
      return response.data.data;
    }
    return null;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const loginAsync = createAsyncThunk<
  LoginReturnData,
  LoginPayload,
  ThunkApi
>('auth/login', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'post',
      url: '/user/login',
      data: payload,
    });

    const { token } = response.data;
    localStorage.setItem('token', token);
    saveUserTokenToAxios(token);
    const user: AuthState = jwt_decode(token);

    return user;
  } catch (err) {
    return rejectWithValue({ message: 'error' });
  }
});

type TelegramLoginReturnData = AuthState;

type TelegramLoginPayload = {
  firstName: string;
  lastName: string;
  telegramId: number;
  avatar: string;
  telegramLogin: string;
};

export const telegramLoginAsync = createAsyncThunk<
  TelegramLoginReturnData,
  TelegramLoginPayload,
  ThunkApi
>('auth/telegram-login', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'post',
      url: '/user/telegram-login',
      data: payload,
    });

    const { token } = response.data;
    localStorage.setItem('token', token);
    saveUserTokenToAxios(token);
    const user: AuthState = jwt_decode(token);
    saveUserTokenToAxios(token);
    return user;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: { logout: LOGOUT },
  extraReducers: (builder) => {
    builder.addCase(initAuthAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(loginAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(telegramLoginAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
