import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import LockIcon from '../common/icons/LockIcon';
import HideIcon from '../common/icons/HideIcon';
import ShowIcon from '../common/icons/ShowIcon';
import ProfileIcon from '../common/icons/ProfileIcon';
import { useAppDispatch } from '../../store';
import { loginAsync, telegramLoginAsync } from '../../store/slices/authSlice';

const Wrapper = styled.div`
  background: #2a2d39;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const LoginWrapper = styled.div``;
const Title = styled.h1`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 30px;
`;
const LoginForm = styled.form``;
const Field = styled.div<{ focus: boolean }>`
  width: 436px;
  height: 60px;
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  padding: 8px 18px;
  align-items: center;
  border: 1px solid;
  border-color: ${({ focus }) => (focus ? '#b9375e' : 'transparent')};
  background: rgba(255, 255, 255, 0.1);
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const Input = styled.input`
  width: 100%;
  display: inline-block;
  height: 100%;
  border-radius: inherit;
  outline: none;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  margin-left: 8px;
  caret-color: #fff;
  background-color: transparent;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const Submit = styled.button`
  width: 436px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  color: #ffffff;
  padding: 20px 40px;
  background: #b9375e;
  box-shadow: 0px 100px 451px rgba(0, 0, 0, 0.84),
    0px 24px 180px rgba(0, 0, 0, 0.555827);
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    background: rgb(129, 38, 65);
  }

  :focus {
    background: rgb(129, 38, 65);
  }

  :active {
    transform: scale(0.98);
    box-shadow: 0px 50px 451px rgba(0, 0, 0, 0.84),
      0px 24px 90px rgba(0, 0, 0, 0.555827);
  }

  :disabled {
    background: rgb(129, 38, 65);
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const IconButton = styled.button`
  cursor: pointer;
`;

const ErrorMessage = styled(motion.p)`
  padding: 20px;
  background: #e7573d;
  border-radius: 16px;
  margin-bottom: 24px;
  color: #fff;
`;

function Login() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [focus, setFocus] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const handleEmailChange = (e) => {
    if (error) {
      setError(false);
    }
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    if (error) {
      setError(false);
    }
    setPassword(e.target.value);
  };
  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  };
  const handleFocus = (e) => {
    setFocus(e.target.name);
  };
  const handleBlur = (e) => {
    setFocus('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await dispatch(loginAsync({ email, password }));
    setLoading(false);
    if (response.meta.requestStatus === 'rejected') {
      setError(true);
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('data-telegram-login', 'PlantIn_expert_help_test_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-radius', '12');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    const scriptSrc = 'https://telegram.org/js/telegram-widget.js?15';
    script.src = scriptSrc;
    script.onload = () => {
      const iframe = document.getElementById(
        'telegram-login-PlantIn_expert_help_test_bot'
      );
      if (iframe) {
        iframe.style.top = '40px';
      }
    };
    document.body.appendChild(script);

    return () => {
      const iFrame = document.getElementById(
        'telegram-login-PlantIn_expert_help_test_bot'
      );
      if (iFrame) {
        document.body.removeChild(iFrame);
      }
    };
  }, []);

  useEffect(() => {
    document.body.addEventListener(
      'telegram_login',
      async function (e: CustomEvent) {
        setLoading(true);
        const payload = {
          firstName: e.detail.first_name,
          lastName: e.detail.last_name,
          telegramId: e.detail.id,
          avatar: e.detail.photo_url,
          telegramLogin: e.detail.username,
        };
        await dispatch(telegramLoginAsync(payload));
        setLoading(false);
        const iFrame = document.getElementById(
          'telegram-login-PlantIn_expert_help_test_bot'
        );
        if (iFrame) {
          document.body.removeChild(iFrame);
        }

        history.push('/');
      },
      false
    );
  }, [dispatch, history]);

  return (
    <Wrapper>
      <LoginWrapper>
        <Title>Log in to your account</Title>
        <LoginForm onSubmit={handleSubmit}>
          <Field focus={focus === 'email'}>
            <ProfileIcon />
            <Input
              required
              value={email}
              placeholder="Email"
              onChange={handleEmailChange}
              name="email"
              type="email"
              autoCorrect="off"
              autoCapitalize="off"
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Field>
          <Field focus={focus === 'password'}>
            <LockIcon />
            <Input
              required
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder="Password"
              onChange={handlePasswordChange}
              name="password"
              autoCorrect="off"
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoComplete="new-password"
            />
            <IconButton onClick={handleClickShowPassword} type="button">
              {showPassword ? <HideIcon /> : <ShowIcon />}
            </IconButton>
          </Field>
          <AnimatePresence>
            {error && (
              <ErrorMessage
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
              >
                Email or password is incorrect
              </ErrorMessage>
            )}
          </AnimatePresence>
          <Submit disabled={loading} type="submit">
            Sign in
          </Submit>
        </LoginForm>
      </LoginWrapper>
    </Wrapper>
  );
}

export default Login;
