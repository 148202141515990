import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* backdrop-filter: blur(10px); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 10000;
  padding: 2rem 1rem;
  perspective: 1000px; ;
`;

const Container = styled(motion.div)`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

function Modal({ children }) {
  const handlePropagation = (
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    e.stopPropagation();
  };
  return (
    <Backdrop>
      <Container
        initial={{ y: -15 }}
        animate={{ y: 0 }}
        exit={{ y: 15, opacity: 0 }}
        onClick={handlePropagation}
      >
        {children}
      </Container>
    </Backdrop>
  );
}

export default Modal;
