import React from 'react';

function ArchiveIcon({ fill = '#fff' }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Light-Outline/Document">
        <g id="Document">
          <path
            id="Fill 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7158 16.9738H8.49579C8.08179 16.9738 7.74579 16.6378 7.74579 16.2238C7.74579 15.8098 8.08179 15.4738 8.49579 15.4738H15.7158C16.1298 15.4738 16.4658 15.8098 16.4658 16.2238C16.4658 16.6378 16.1298 16.9738 15.7158 16.9738Z"
          />
          <path
            id="Fill 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7158 12.7872H8.49579C8.08179 12.7872 7.74579 12.4512 7.74579 12.0372C7.74579 11.6232 8.08179 11.2872 8.49579 11.2872H15.7158C16.1298 11.2872 16.4658 11.6232 16.4658 12.0372C16.4658 12.4512 16.1298 12.7872 15.7158 12.7872Z"
          />
          <path
            id="Fill 5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2505 8.61047H8.49551C8.08151 8.61047 7.74551 8.27447 7.74551 7.86047C7.74551 7.44647 8.08151 7.11047 8.49551 7.11047H11.2505C11.6645 7.11047 12.0005 7.44647 12.0005 7.86047C12.0005 8.27447 11.6645 8.61047 11.2505 8.61047Z"
          />
          <g id="Group 9">
            <mask
              id="mask0"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="3"
              y="2"
              width="19"
              height="20"
            >
              <path
                id="Clip 8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2.00024H21.1647V21.91H3V2.00024Z"
              />
            </mask>
            <g mask="url(#mask0)">
              <path
                id="Fill 7"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9087 3.5L8.2197 3.504C5.89169 3.518 4.49969 4.958 4.49969 7.357V16.553C4.49969 18.968 5.90469 20.41 8.25569 20.41L15.9447 20.407C18.2727 20.393 19.6647 18.951 19.6647 16.553V7.357C19.6647 4.942 18.2607 3.5 15.9087 3.5ZM8.25669 21.91C5.11269 21.91 2.99969 19.757 2.99969 16.553V7.357C2.99969 4.124 5.04669 2.023 8.21469 2.004L15.9077 2H15.9087C19.0527 2 21.1647 4.153 21.1647 7.357V16.553C21.1647 19.785 19.1177 21.887 15.9497 21.907L8.25669 21.91Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ArchiveIcon;
