import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { useAppDispatch, useAppSelector } from '../../../store';
import { logout } from '../../../store/slices/authSlice';
import HomeIcon from '../icons/HomeIcon';
import LogoutIcon from '../icons/LogoutIcon';
import ArchiveIcon from '../icons/ArchiveIcon';
import UserIcon from '../icons/UserIcon';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../lib/services/images';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--white);
  padding: 32px 20px 60px 20px;
  background-color: var(--black);
  border-radius: 0px 20px 20px 0px;
  box-shadow: 0px 22.3363px 100.737px rgba(0, 0, 0, 0.408001),
    0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const UserInfo = styled.div`
  margin-bottom: 60px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Ellipse = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 16px;
`;
const Tabs = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

type TabProps = {
  isActive?: boolean;
};

const tabShadow = `box-shadow: 0px 100px 451px rgba(0, 0, 0, 0.84), 0px 41.7776px 188.417px rgba(0, 0, 0, 0.555827),
0px 22.3363px 100.737px rgba(0, 0, 0, 0.408001), 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357), 0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);`;

const Tab = styled.span<TabProps>`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 8px;
  cursor: pointer;
  ${({ isActive }) => isActive && tabShadow}
`;

const LogoutButton = styled.button`
  margin-top: auto;
  width: 60px;
  height: 60px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

function Sidebar() {
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  if (!auth) {
    return null;
  }
  return (
    <Wrapper>
      <Link to={`/user/${auth._id}`}>
        <UserInfo>
          <Ellipse src={auth.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
          <p>
            {auth.firstName} {auth.lastName}
          </p>
        </UserInfo>
      </Link>

      <Tabs>
        <Link to="/">
          <Tooltip placement="right" title="Dashboard">
            <Tab isActive={location.pathname === '/'}>
              <HomeIcon />
            </Tab>
          </Tooltip>
        </Link>
        <Link to="/archive">
          <Tooltip placement="right" title="Archive">
            <Tab isActive={location.pathname === '/archive'}>
              <ArchiveIcon />
            </Tab>
          </Tooltip>
        </Link>
        {auth.isAdmin && (
          <Link to="/users">
            <Tooltip placement="right" title="Users">
              <Tab isActive={location.pathname === '/users'}>
                <UserIcon fill={'#fff'} />
              </Tab>
            </Tooltip>
          </Link>
        )}
      </Tabs>
      <LogoutButton
        onClick={() => {
          dispatch(logout());
        }}
      >
        <LogoutIcon />
      </LogoutButton>
    </Wrapper>
  );
}

export default Sidebar;
