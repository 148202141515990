import Button from '@material-ui/core/Button';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from '../../../../lib/api/axios';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../../lib/services/images';
import { useAppSelector } from '../../../../store';

const Wrapper = styled.div`
  background: #626275;
  padding: 24px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const Title = styled.h4`
  font-size: 16px;
  margin-bottom: 16px;
  color: var(--accent);

  span {
    color: var(--primary);
  }
`;

const List = styled.ul`
  min-width: 300px;
  margin-bottom: 36px;
`;

const UserItem = styled.li<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 8px;

  :hover {
    background-color: #232530;
  }

  ${({ isSelected }) =>
    isSelected && 'background-color: var(--primary) !important'}
`;

const Avatar = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.p`
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  button:last-of-type {
    margin-left: 16px;
  }
`;

interface ChangeAuthorModalProps {
  handleClose: () => void;
  taskId: string;
}

const CreateTaskModal: React.FC<ChangeAuthorModalProps> = ({
  taskId,
  handleClose,
}) => {
  const users = useAppSelector((state) => state.users);
  const task = useAppSelector((state) => state.taskboard.tasks[taskId]);
  const [selectedUser, setSelectedUser] = useState(null);

  const selectAuthor = (user) => () => {
    setSelectedUser(user);
  };

  const executor = users.find((user) => user._id === task.executor._id);

  const handleSubmit = async () => {
    try {
      await axios({
        method: 'put',
        url: `/task/reassign`,
        data: {
          taskId,
          newAuthor: selectedUser._id,
          newAuthorTelegramId: selectedUser.telegramId,
          previousAuthorTelegramId: executor.telegramId,
        },
      });

      handleClose();
    } catch (err) {
      // setError('Something goes wrong');
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Title>
        Assign new author for task <span>{task.requestId}</span>
      </Title>
      <List>
        {users.map((user) => {
          if (executor._id === user._id || user.isDeleted) {
            return null;
          }
          return (
            <UserItem
              key={user._id}
              onClick={selectAuthor(user)}
              isSelected={selectedUser && selectedUser._id === user._id}
            >
              <Avatar src={user.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
              <Name>
                {user.firstName} {user.lastName}
              </Name>
            </UserItem>
          );
        })}
      </List>
      <Actions>
        <Button
          color="primary"
          variant="text"
          disableElevation
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disableElevation
          variant="contained"
          disabled={!selectedUser}
          onClick={handleSubmit}
        >
          Assign
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default CreateTaskModal;
