import dayjs from 'dayjs';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { fetchPaymentsAsync, Payment } from '../../../store/slices/paymentsSlice';
import { openModal } from '../../../store/slices/uiSlice';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.h3`
  color: #545970;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #24252d;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;
`;

const List = styled.ul`
  max-height: 360px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Item = styled.li`
  border-bottom: 1px solid #444650;
  padding: 8px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span:last-of-type {
    font-weight: 600;
  }
`;

const Button = styled.button`
  color: #b0b4c2;
  cursor: pointer;
  background: #3b4150;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    color: #fff;
    background: #464d5c;
  }
`;

const More = styled.button`
  color: #b0b4c2;
  border: 1px solid #3b4150;
  border-radius: 6px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;

  &:hover {
    color: #fff;
    border-color: #464d5c;
  }

  span {
    font-weight: 600;
  }
`;

const Body = styled.div`
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const NoPayments = styled.p`
  margin-bottom: 16px;
`;

function PaymentHistory({ auth, isCalendar, user, toogleCalendar }) {
  const dispatch = useAppDispatch();
  const payments = useAppSelector((state) => state.payments);
  const filteredPayments = payments.filter(
    (payment) => payment.user === user?._id
  );

  useEffect(() => {
    dispatch(fetchPaymentsAsync());
  }, [dispatch]);

  return (
    <Wrapper>
      <Title>Payments</Title>
      {filteredPayments.length === 0 ? (
        <NoPayments>No payments</NoPayments>
      ) : (
        <List>
          {filteredPayments.map((payment) => {
            return <Record key={payment._id} user={user?._id} payment={payment} />;
          })}
        </List>
      )}
    </Wrapper>
  );
}

function Record({payment, user}: {payment: Payment, user: string}) {
  const [more, setMore] = useState(false);

  const dispatch = useAppDispatch();
  const collection = useAppSelector((state) => state.taskboard.userTasks[user] ?? []);

  const tasks = Object.fromEntries(collection.map((task) => {
    return [task._id, task];
  }));

  const from = payment.tasks.length > 0 ? tasks[payment.tasks[0]] : null;
  const to = payment.tasks.length > 1 ? tasks[payment.tasks[payment.tasks.length - 1]] : null;

  return (
    <>
      <Item>
        <Header>
          <span>
            {dayjs(payment.from).format('DD.MM.YY')} -{' '}
            {dayjs(payment.to).format('DD.MM.YY')}
          </span>
          <span>{payment.price} USD</span>
        </Header>
        {payment.tasks.length > 0 && (
          <Body>
            {more ? (
              <>
                {[...payment.tasks,...payment.tasks, ...payment.tasks, ...payment.tasks, ...payment.tasks, ...payment.tasks].map((id) => {
                  var task = tasks[id];
    
                  return (
                    <Tooltip placement="top" title={dayjs(task.finishedAt).format('DD.MM.YY, HH:mm')}>
                      <Button
                        key={id}
                        type="button"
                        onClick={() => {
                          dispatch(
                            openModal({
                              type: 'task',
                              props: task,
                            })
                          )
                        }}
                      >
                        {task.requestId}
                      </Button>
                    </Tooltip>
                  );
                })}
                <More
                  type="button"
                  onClick={() => {
                    setMore(false);
                  }}
                >
                  HIDE
                </More>
              </>
            ) : (
              <>
                {from && (
                  <Tooltip placement="top" title={dayjs(from.finishedAt).format('DD.MM.YY, HH:mm')}>
                    <Button
                      type="button"
                      onClick={() => {
                        dispatch(
                          openModal({
                            type: 'task',
                            props: from,
                          })
                        )
                      }}
                    >
                      {from.requestId}
                    </Button>
                  </Tooltip>
                )}

                {payment.tasks.length > 2 && (
                  <More
                    type="button"
                    onClick={() => {
                      setMore(true);
                    }}
                  >
                    MORE (<span>{payment.tasks.length}</span>)
                  </More>
                )}

                {to && (
                  <Tooltip placement="top" title={dayjs(to.finishedAt).format('DD.MM.YY, HH:mm')}>
                    <Button
                      type="button"
                      onClick={() => {
                        dispatch(
                          openModal({
                            type: 'task',
                            props: to,
                          })
                        )
                      }}
                    >
                      {to.requestId}
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </Body>
        )}
      </Item>
    </>
  );
}

export default PaymentHistory;
