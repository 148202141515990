import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import ReactCalendar from 'react-calendar';
import Button from '@material-ui/core/Button';
import { motion } from 'framer-motion';
import './Calendar.css';
import { useAppDispatch, useAppSelector } from '../../../store';
import { createPaymentAsync } from '../../../store/slices/paymentsSlice';
import { User } from '../../../store/slices/usersSlice';

const Wrapper = styled.div`
  padding: 1.5rem;
  position: relative;
  max-width: 350px;
  background: #3b4050;
  border-radius: 1rem;
  z-index: 1;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const CalendarSwitch = styled.div`
  position: relative;
  display: flex;
  height: 44px;
  width: 100%;
  margin-bottom: 20px;
`;

const getSwitchButtonColor = (active, disabled) => {
  if (active && disabled) {
    return '#CBD4E3';
  }
  if (active) {
    return '#b9385e';
  }
  return '#24242e';
};

const SwitchButton = styled.button<{ active: boolean }>`
  background: #e0e7f1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;
  width: 52%;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: ${({ active }) => (active ? 1 : 0)};
  color: ${({ active }) => (active ? '#fff' : '#91A0B6')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  background-color: ${({ active, disabled }) =>
    getSwitchButtonColor(active, disabled)};

  & > span:first-child {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
  }
  & > span:last-child {
    font-weight: normal;
    font-size: 14px;
  }
`;

const FromButton = styled(SwitchButton)`
  left: 0;
`;
const ToButton = styled(SwitchButton)`
  right: 0;
`;

const Actions = styled.div`
  margin-top: 1rem;
  text-align: right;

  & > button:last-of-type {
    margin-left: 16px;
  }
`;

const Form = styled(motion.div)`
  padding-top: 8px;
`;

const TasksAmount = styled.p`
  color: #fff;
  padding: 12px 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 8px 0px;
  background-color: #2a2d39;

  span {
    color: #b9385e;
    font-weight: 600;
    font-size: 1.2em;
    margin-left: auto;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;

  border-radius: 8px;
  background-color: #2a2d39;
  border: 2px solid#b9385e;
`;

const Input = styled.input`
  width: 80%;
  outline: none;
  caret-color: #b9385e;
  color: #fff;
  font-size: 18px;
  background-color: transparent;
`;

const Currency = styled.p``;

const SuccessOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);
`;

const SuccessMessage = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #1f877f;
  border-radius: 8px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

interface CalculatorProps {
  toogleCalendar: () => void;
  user: User;
}

const getStartOfTheDay = () => {
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  return start;
};

const getEndOfTheDay = () => {
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  return end;
};

const PaymentCalculator: React.FC<CalculatorProps> = ({
  user,
  toogleCalendar,
}) => {
  const dispatch = useAppDispatch();
  const [paymentForm, setPaymentForm] = useState(false);

  const task_collection = useAppSelector((state) => state.taskboard.userTasks[user._id]);

  const [price, setPrice] = useState(0);
  const [success, setSuccess] = useState(false);
  const [tasksAmount, setTasksAmount] = useState(0);
  const [view, setView] = useState('from');
  const [fromCalendarValue, setFromCalendarValue] = useState(
    getStartOfTheDay()
  );
  const [toCalendarValue, setToCalendarValue] = useState(getEndOfTheDay());
  const [fromCalendarActiveValue, setFromCalendarActiveValue] = useState(
    getStartOfTheDay()
  );
  const [toCalendarActiveValue, setToCalendarActiveValue] = useState(
    getEndOfTheDay()
  );

  const openPaymentForm = () => {
    setPaymentForm(true);
  };

  useEffect(() => {
    const fromDateTimestamp = fromCalendarValue.getTime();
    const toDateTimestamp = toCalendarValue.getTime() + 86400000 - 1;
    const result = task_collection.filter((task) => {
      if (
        task.status >= 2 &&
        task.paid == false &&
        task.executor._id === user._id &&
        new Date(task.approvedAt).getTime() >= fromDateTimestamp &&
        new Date(task.approvedAt).getTime() <= toDateTimestamp
      ) {
        return true;
      }
      return false;
    });
    setTasksAmount(result.length);
  }, [fromCalendarValue, task_collection, toCalendarValue, user._id]);

  const handleConfirm = async () => {
    const fromDate = fromCalendarValue.getTime();
    const toDate = toCalendarValue.getTime();
    const fromIso = new Date(fromDate).toISOString();
    const toIso = new Date(toDate).toISOString();
    const result = task_collection.filter((task) => {
      if (
        task.status >= 2 &&
        task.paid == false &&
        task.executor._id === user._id &&
        new Date(task.approvedAt).getTime() >= fromDate &&
        new Date(task.approvedAt).getTime() <= toDate
      ) {
        return true;
      }
      return false;
    });
    const payload = {
      from: fromIso,
      to: toIso,
      price,
      user: user._id,
      tasks: result.map((item) => item._id),
    };

    if (price) {
      await dispatch(createPaymentAsync(payload));
      setSuccess(true);
      setTimeout(() => {
        toogleCalendar();
      }, 1000);
    }
  };

  const toogleCalendarView = (newView) => (e) => {
    e.stopPropagation();
    setView(newView);
  };

  const handleCalendarChange = (value) => {
    if (view === 'from') {
      setFromCalendarValue(value);
    } else {
      setToCalendarValue(value);
    }
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    if (view === 'from') {
      setFromCalendarActiveValue(activeStartDate);
    } else {
      setToCalendarActiveValue(activeStartDate);
    }
  };

  const handleChange = (e) => {
    if (!Number.isNaN(Number(e.target.value))) {
      setPrice(+e.target.value);
    }
  };

  const dateFormat = 'MMM DD YYYY';

  const fromDate = fromCalendarValue
    ? dayjs(fromCalendarValue).format(dateFormat)
    : '---';

  const toDate = toCalendarValue
    ? dayjs(toCalendarValue).format(dateFormat)
    : '---';

  const calendarValue = view === 'from' ? fromCalendarValue : toCalendarValue;

  const activeStartDate =
    view === 'from' ? fromCalendarActiveValue : toCalendarActiveValue;

  return (
    <Wrapper>
      <CalendarSwitch>
        <FromButton
          type="button"
          onClick={toogleCalendarView('from')}
          active={view === 'from'}
        >
          <span>From</span>
          <span>{fromDate}</span>
        </FromButton>
        <ToButton
          type="button"
          onClick={toogleCalendarView('to')}
          active={view === 'to'}
        >
          <span>To</span>
          <span>{toDate}</span>
        </ToButton>
      </CalendarSwitch>
      <ReactCalendar
        maxDetail="month"
        minDetail="month"
        maxDate={new Date()}
        minDate={view === 'to' ? fromCalendarValue : null}
        tileClassName="active_tile"
        value={calendarValue}
        activeStartDate={activeStartDate}
        onChange={handleCalendarChange}
        onActiveStartDateChange={handleActiveStartDateChange}
      />
      <TasksAmount>
        Finished tasks: <span>{tasksAmount}</span>
      </TasksAmount>
      {paymentForm && (
        <Form
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <InputWrapper>
            <Input autoFocus value={price} onChange={handleChange} />
            <Currency>USD</Currency>
          </InputWrapper>
        </Form>
      )}
      <Actions>
        {paymentForm ? (
          <Button
            type="button"
            color="primary"
            disableElevation
            variant="text"
            onClick={() => {
              setPaymentForm(false);
            }}
          >
            Cancel
          </Button>
        ) : (
          <Button
            type="button"
            color="primary"
            disableElevation
            variant="text"
            onClick={toogleCalendar}
          >
            Close
          </Button>
        )}
        {paymentForm ? (
          <Button
            type="button"
            color="primary"
            disableElevation
            variant="contained"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        ) : (
          <Button
            type="button"
            color="primary"
            disableElevation
            variant="contained"
            onClick={openPaymentForm}
          >
            Create payment
          </Button>
        )}
      </Actions>
      {success && (
        <SuccessOverlay>
          <SuccessMessage
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Payment successfully created <span>👌</span>
          </SuccessMessage>
        </SuccessOverlay>
      )}
    </Wrapper>
  );
};

export default React.memo(PaymentCalculator);
