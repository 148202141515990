import React, { useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextareaAutosize from 'react-textarea-autosize';
import { motion } from 'framer-motion';
import {
  deleteCommentAsync,
  updateCommentAsync,
} from '../../../../store/slices/taskboardSlice';
import { useAppDispatch } from '../../../../store';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const FormWrapper = styled.div`
  flex-grow: 1;
`;

const CommentInfo = styled.div`
  font-size: 14px;
  color: #d3d3d3;
  margin-bottom: 8px;
`;
const Author = styled.span`
  margin-right: 8px;
  font-weight: 600;
`;
const Time = styled.span``;
const Form = styled.form`
  padding: 12px;
  border-radius: 8px;
  background-color: #24242e;
`;
const Message = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: #d3d3d3;
  font-weight: 500;
  background-color: #24242e;
`;
const MessageField = styled(TextareaAutosize)`
  width: 100%;
  outline: none;
  margin-bottom: 1rem;
  font-family: inherit;
  padding: 0;
  font-size: 16px;
  display: block;
  outline: none;
  line-height: 20px;
  caret-color: var(--primary);
  background-color: #24242e;
  color: #d3d3d3;
  font-weight: 500;
  height: 20px;
  min-height: 20px;
  overflow: hidden;
  resize: none;
  margin: 0;
  font-size: 14px;
  overflow-wrap: break-word;
  -webkit-appearance: none;
`;
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`;
const Actions = styled(motion.div)`
  padding-top: 12px;
  button:first-child {
    margin-right: 8px;
  }
`;

const EditOptions = styled.div`
  padding: 4px 12px;
  font-size: 14px;

  button {
    color: #ffffff;
    opacity: 0.6;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }

  button:hover {
    opacity: 0.8;
  }

  button:first-child {
    margin-right: 12px;
  }
`;

function Comment({ data, taskId, auth }) {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(data.text);
  const [isEditable, setIsEditable] = useState(false);
  const openForm = () => {
    setIsEditable(true);
  };
  const closeForm = () => {
    setIsEditable(false);
    setValue(data.text);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(
      updateCommentAsync({ taskId, text: value, commentId: data._id })
    );
    setIsEditable(false);
  };

  const deleteComment = async () => {
    dispatch(deleteCommentAsync({ taskId, commentId: data._id }));
  };

  const author =
    typeof data.author === 'string' && data.author === auth._id
      ? auth
      : data.author;

  return (
    <Wrapper>
      <Avatar src={author.avatar} />
      <FormWrapper>
        <CommentInfo>
          <Author>
            {author.firstName} {author.lastName}
          </Author>
          <Time>{dayjs(data.createdAt).format('DD.MM.YY, HH:mm')}</Time>
        </CommentInfo>
        <Form onSubmit={handleSubmit}>
          {isEditable ? (
            <MessageField autoFocus value={value} onChange={handleChange} />
          ) : (
            <Message>{data.text}</Message>
          )}
          {isEditable && (
            <Actions initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
              >
                Send
              </Button>
              <Button
                size="small"
                variant="text"
                color="primary"
                disableElevation
                type="button"
                onClick={closeForm}
              >
                Cancel
              </Button>
            </Actions>
          )}
        </Form>
        {!isEditable && author._id === auth._id && (
          <EditOptions>
            <button type="button" onClick={openForm}>
              Edit
            </button>
            <button type="button" onClick={deleteComment}>
              Delete
            </button>
          </EditOptions>
        )}
      </FormWrapper>
    </Wrapper>
  );
}

export default React.memo(Comment);
