function PlusIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="4" width="1.5" height="16" rx="0.75" fill="white" />
      <rect
        x="3.75"
        y="12.75"
        width="1.5"
        height="16"
        rx="0.75"
        transform="rotate(-90 3.75 12.75)"
        fill="white"
      />
    </svg>
  );
}

export default PlusIcon;
