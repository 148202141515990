import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import Table from './Table';
import FilterBar from './FilterBar';
import {
  fetchArchiveTasksAsync,
  FetchArchiveTasksPayload,
} from '../../store/slices/taskboardSlice';
import {
  getAllUsersAsync,
} from '../../store/slices/usersSlice';
import { useUrlParamsFilter } from '../../hooks/useUrlParamsFilter';

const initialState = {
  executor: null,
  proofreader: null,
  startTime: Date.now() - 604800000,
  endTime: Date.now() + 86400000,
};

function Archive() {
  const auth = useAppSelector((state) => state.auth);
  const location = useLocation();

  const { filterState, handleFiltersChange } = useUrlParamsFilter<FetchArchiveTasksPayload>(initialState);

  const fetchDispatch = useAppDispatch();

  // Create effect that will fetch all tasks when component is mounted or when filterState changes
  useEffect(() => {
    if (auth._id) {
      const urlParams = new URLSearchParams(location?.search);
      const filterState = {
        ...initialState,
        ...Object.fromEntries(urlParams),
      };
      fetchDispatch(
        fetchArchiveTasksAsync(
          filterState
        )
      ).then();
    }
  }, [auth, fetchDispatch, location]);

  useEffect(() => {
    if (auth._id) {
      fetchDispatch(getAllUsersAsync({})).then();
    }
  }, [auth, fetchDispatch]);

  const tasks = useAppSelector((state) => state.taskboard.archive);
  const users = useAppSelector((state) => state.users);

  return (
    <div>
      <FilterBar
        users={users}
        filterState={filterState}
        handleFiltersChange={handleFiltersChange}
      />
      <Table users={users} tasks={tasks} filterState={filterState} />
    </div>
  );
}

export default Archive;
