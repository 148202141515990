import { useState } from 'react';
import styled from 'styled-components';

import TextField from '../../forms/TextField';
import PhoneField from '../../forms/PhoneField';
import CreditCardField from '../../forms/CreditCardField';
import { Actions, StyledButton } from './styled-components';

const Form = styled.form`
  min-width: 350px;
  > div {
    margin-bottom: 18px;
  }
`;

function StepTwo({ values, handleStepSubmit, handleBack }) {
  const [fields, setFields] = useState({
    phone: { value: values.phone, error: '' },
    creditCard: { value: values.creditCard, error: '' },
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { phone, creditCard } = fields;
    handleStepSubmit({
      phone: phone.value,
      creditCard: creditCard.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFields((state) => ({
      ...state,
      phone: { ...state.phone, value },
    }));
  };

  const handleCreditCardChange = (value) => {
    setFields((state) => ({
      ...state,
      creditCard: { ...state.creditCard, value },
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PhoneField
        onChange={handlePhoneChange}
        initialValue={fields.phone.value}
      />
      <CreditCardField
        initialValue={fields.creditCard.value}
        onChange={handleCreditCardChange}
      />
      <Actions>
        <StyledButton
          type="button"
          variant="text"
          color="primary"
          disableElevation
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          type="submit"
          color="primary"
          disableElevation
          variant="contained"
        >
          {fields.phone.value.length === 0 &&
          fields.creditCard.value.length === 0
            ? 'Skip'
            : 'Next'}
        </StyledButton>
      </Actions>
    </Form>
  );
}

export default StepTwo;
