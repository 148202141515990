import { useLocation } from 'react-router-dom';
import TaskPopup from './TaskPopup';

function TaskPopupManager() {
  const location = useLocation();

  if (!location.search || !location.search.includes('task')) {
    return null;
  }

  return <TaskPopup />;
}

export default TaskPopupManager;
