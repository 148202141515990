import { useEffect, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { useAppSelector } from '../../../store';
import Task from './Task';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 50;
  padding: 2rem 1rem;
  perspective: 1000px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Container = styled(motion.div)`
  position: relative;
  margin-bottom: 2rem;
  max-height: calc(100% - 64px);
  max-width: 940px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

function TaskPopup() {
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  const taskId = queryString.split('task=')[1];
  const task = useAppSelector((state) => state.taskboard.tasks[taskId]);
  const handleClose = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [task, handleClose]);

  useEffect(() => {
    disableBodyScroll(ref.current);
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  if (!task) {
    return null;
  }

  return createPortal(
    <Backdrop ref={ref}>
      <Container initial={{ y: -15 }} animate={{ y: 0 }} exit={{ y: 20 }}>
        <Task task={task} handleClose={handleClose} />
      </Container>
    </Backdrop>,
    document.body,
    'task-popup'
  );
}

export default TaskPopup;
