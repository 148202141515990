import React, { useEffect } from 'react';
import styled from 'styled-components';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import CloseIcon from '@material-ui/icons/Close';

export const FieldWrapper = styled.div`
  position: relative;
  :hover {
    .divider {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const Label = styled.label<{
  active: boolean;
}>`
  position: relative;
  font-weight: 500;
  line-height: 1;
  font-size: 14px;
  transition: all 0.1s ease-in-out;
  color: ${({ active }) => (active ? '#b9375e' : '#545970')};
  display: inline-flex;
  align-items: center;

  > span:first-child {
    margin-right: 8px;
  }
`;

export const Border = styled.div<{ isFocused: boolean }>`
  height: 1px;
  width: 100%;
  position: relative;
  background-color: #545970;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #b9375e;
    transition: transform 0.1s ease-in-out;
    transform: ${({ isFocused }) => (isFocused ? 'scaleX(1)' : 'scaleX(0)')};
  }
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 4px 4px 4px 0px;
  color: #d4d4d4;
  outline: none;
  border: none;
  background: transparent;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #d4d4d4;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  padding-top: 4px;
  color: #b9375e;
  font-weight: 600;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const List = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 204px;
  overflow-y: auto;
  z-index: 30;
  background-color: #20222d;
  border-radius: 10px;
  padding: 10px 0;
  margin-top: 4px;

  li {
    cursor: pointer;
    padding: 6px 12px;
    line-height: 1.3;
    font-size: 15px;
  }

  li[aria-selected="true"] {
    background-color: #181a23;
  }

  li[data-focus="true"] {
    background-color: #14151f;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 5px 0;
  line-height: 22px;
  border: 1px solid #b9375e;
  border-radius: 6px;
  box-sizing: content-box;
  padding: 4px 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

interface AutocompleteFieldProps {
  name?: string;
  error?: string;
  label?: string;
  inputProps?: any;
  initialValue?: string;
  placeholder?: string;
  required?: boolean;
  multiple?: boolean;
  options?: string[];
  onChange?: (value: any) => void;
  freeSolo?: boolean;
}

const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  name,
  error,
  label,
  required = false,
  placeholder,
  inputProps,
  initialValue = '',
  options = [],
  freeSolo = false,
  multiple = false,
  onChange,
}) => {
  const {
    value,
    focused,
    inputValue,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    options,
    multiple,
    getOptionLabel: (option: string) => option,
    getOptionSelected: (option, value) => {
      return option === value;
    },
    defaultValue: multiple ? initialValue.split(", ") : initialValue,
    onChange: (e, value) => {
      onChange && onChange((multiple ? (value as string[]).filter((value) => value.trim()).join(", ") : value) || '');
    },
    freeSolo,
  });

  useEffect(() => {
    if (freeSolo && onChange) {
      onChange(inputValue);
    }
  }, [freeSolo && inputValue])

  return (
    <Wrapper>
      <FieldWrapper {...getRootProps()}>
        {label && (
          <Label htmlFor={name} active={focused} {...getInputLabelProps()}>
            <span>
              {label}
              {required && '*'}
            </span>
            {error && (
              <span>
                <WarningRoundedIcon
                  fontSize="small"
                  style={{ color: 'b9375e' }}
                />
              </span>
            )}
          </Label>
        )}

        {multiple && (value as string[]).filter((value) => value.trim()).map((value, index) => (
          <Tag label={value} {...getTagProps({ index })} />
        ))}

        <StyledInput
          id={name}
          name={name}
          placeholder={placeholder}
          {...getInputProps()}
          {...inputProps}
          required={required}
        />
        <Border className="divider" isFocused={focused} />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FieldWrapper>

      {groupedOptions.length > 0 && (
        <List {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option}</li>
          ))}
        </List>
      )}
    </Wrapper>
  );
};

export default React.memo(AutocompleteField);