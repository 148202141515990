function ProfileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="4"
          y="14"
          width="16"
          height="8"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 14.4961H19.8399V21.8701H4V14.4961Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9209 15.9961C7.65989 15.9961 5.49989 16.7281 5.49989 18.1731C5.49989 19.6311 7.65989 20.3701 11.9209 20.3701C16.1809 20.3701 18.3399 19.6381 18.3399 18.1931C18.3399 16.7351 16.1809 15.9961 11.9209 15.9961ZM11.9209 21.8701C9.96189 21.8701 3.99989 21.8701 3.99989 18.1731C3.99989 14.8771 8.52089 14.4961 11.9209 14.4961C13.8799 14.4961 19.8399 14.4961 19.8399 18.1931C19.8399 21.4891 15.3199 21.8701 11.9209 21.8701Z"
            fill="white"
          />
        </g>
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="6"
          y="2"
          width="12"
          height="11"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.60986 2.00012H17.2299V12.6187H6.60986V2.00012Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9209 3.42776C9.77989 3.42776 8.03789 5.16876 8.03789 7.30976C8.03089 9.44376 9.75989 11.1838 11.8919 11.1918L11.9209 11.9058V11.1918C14.0609 11.1918 15.8019 9.44976 15.8019 7.30976C15.8019 5.16876 14.0609 3.42776 11.9209 3.42776ZM11.9209 12.6188H11.8889C8.9669 12.6098 6.59989 10.2268 6.60989 7.30676C6.60989 4.38176 8.99189 1.99976 11.9209 1.99976C14.8489 1.99976 17.2299 4.38176 17.2299 7.30976C17.2299 10.2378 14.8489 12.6188 11.9209 12.6188Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}

export default ProfileIcon;
