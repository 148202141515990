import styled from 'styled-components';
import { useAppSelector } from '../../../../store';
import Comments from './Comments';
import NewComment from './NewComment';

const Wrapper = styled.div``;

const Title = styled.h3`
  color: #979797;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 1;
  font-size: 14px;
  text-transform: uppercase;
`;

function TaskComments({ task }) {
  const auth = useAppSelector((state) => state.auth);
  return (
    <Wrapper>
      <Title>Comments</Title>
      <NewComment auth={auth} task={task} />
      {task.comments && <Comments task={task} auth={auth} />}
    </Wrapper>
  );
}

export default TaskComments;
