import React, { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import Button from '@material-ui/core/Button';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';

import PaymentCalculator from './PaymentCalculator';
import PaymentHistory from './PaymentHistory';
import { useAppSelector } from '../../../store';
import Modal from '../../common/modals/Modal';

const Wrapper = styled.div`
  margin-left: 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  padding: 24px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 20px;

  @media screen and (max-width: 960px) {
    margin-left: 0px;
  }
`;

function UserCardWidget({ user }) {
  const auth = useAppSelector((state) => state.auth);
  const [open, setOpen] = useState(false);

  const toogleCalendar = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Wrapper>
      <PaymentHistory
        auth={auth}
        user={user}
        isCalendar={open}
        toogleCalendar={toogleCalendar}
      />
      {!user.isDeleted && auth.isAdmin && (
        <Button
          color="secondary"
          variant="contained"
          disableElevation
          startIcon={<AccountBalanceWalletRoundedIcon />}
          onClick={toogleCalendar}
        >
          New payment
        </Button>
      )}
      <AnimatePresence>
        {open && (
          <Modal>
            <PaymentCalculator
              user={user}
              toogleCalendar={toogleCalendar}
            />
          </Modal>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

export default UserCardWidget;
