import Comment from './Comment';

function Comments({ task, auth }) {
  return (
    <div>
      {task.comments.map((comment) => (
        <Comment
          key={comment._id}
          data={comment}
          taskId={task._id}
          auth={auth}
        />
      ))}
    </div>
  );
}

export default Comments;
