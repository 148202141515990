import { useAppDispatch, useAppSelector } from '../../store';
import { useEffect } from 'react';
import {
  getAllUsersAsync,
  GetAllUsersPayload,
} from '../../store/slices/usersSlice';
import { UsersTable } from './UsersTable';
import { UsersFilter } from './UsersFilter';
import styled from 'styled-components';
import { useUrlParamsFilter } from '../../hooks/useUrlParamsFilter';
import { useLocation } from 'react-router-dom';

const initialState = {
  search: '',
  isDeleted: false,
};

const UsersContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

function Users() {
  const auth = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { filterState, handleFiltersChange } =
    useUrlParamsFilter<GetAllUsersPayload>(initialState);

  useEffect(() => {
    if (auth._id) {
      const urlParams = new URLSearchParams(location?.search);
      const filterState = {
        ...initialState,
        ...Object.fromEntries(urlParams),
      };
      dispatch(getAllUsersAsync(filterState)).then();
    }
  }, [auth, dispatch, location]);

  const users = useAppSelector((state) => state.users);

  return (
    <UsersContainer>
      <UsersFilter
        filterState={filterState}
        handleFiltersChange={handleFiltersChange}
      />
      <UsersTable users={users} />
    </UsersContainer>
  );
}

export default Users;
