/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface Modal {
  type: string;
  props?: any;
}

interface UiState {
  modal: Modal;
}

const initialState: UiState = {
  modal: { type: '', props: {} },
};

type OpenModalPayload = Modal;

const OPEN_MODAL: CaseReducer<UiState, PayloadAction<OpenModalPayload>> = (
  state,
  action
) => {
  state.modal = action.payload;
};

const CLOSE_MODAL: CaseReducer<UiState> = (state) => {
  state.modal = initialState.modal;
};

const authSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: { openModal: OPEN_MODAL, closeModal: CLOSE_MODAL },
});

export const { openModal, closeModal } = authSlice.actions;

export default authSlice.reducer;
