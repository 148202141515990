import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';

const FieldWrapper = styled.div`
  position: relative;
  :hover {
    .divider {
      background-color: #838aac;
    }
  }
`;

const Label = styled.label<{ isFocused: boolean }>`
  font-weight: 500;
  line-height: 1;
  font-size: 14px;
  transition: color 0.1s ease-in-out;
  color: ${({ isFocused }) => (isFocused ? '#b9375e' : '#545970')};
`;

const Border = styled.div<{ isFocused: boolean }>`
  height: 2px;
  width: 100%;
  position: relative;
  background-color: #545970;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #b9375e;
    transition: transform 0.1s ease-in-out;
    transform: ${({ isFocused }) => (isFocused ? 'scaleX(1)' : 'scaleX(0)')};
  }
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 4px 4px 4px 0px;
  color: #d4d4d4;
  outline: none;
  border: none;
  background: transparent;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #d4d4d4;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  right: 8px;
  top: 24px;
`;

const ErrorMessage = styled.p`
  font-size: 14px;
  padding-top: 4px;
  color: #b9375e;
  font-weight: 600;
`;

interface PasswordFieldProps {
  name?: string;
  error?: string;
  label?: string;
  inputProps?: any;
  initialValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  error,
  label,
  placeholder,
  inputProps,
  initialValue = '',
  onChange,
}) => {
  const [show, setShow] = useState(false);
  const [value, setPhone] = useState(initialValue);
  const [focus, setFocus] = useState(false);
  const handleChange = (event) => {
    setPhone(event.target.value);
    if (onChange) {
      onChange(event.target);
    }
  };
  const handleFocus = () => {
    setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };

  const toogleShow = () => {
    setShow((prev) => !prev);
  };

  return (
    <FieldWrapper>
      {label && (
        <Label htmlFor={name} isFocused={focus}>
          {label}
        </Label>
      )}
      <StyledInput
        id={name}
        name={name}
        type={show ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        {...inputProps}
      />
      <StyledIconButton tabIndex="-1" onClick={toogleShow} size="small">
        {show ? (
          <VisibilityOffRoundedIcon style={{ color: '#545970' }} />
        ) : (
          <VisibilityRoundedIcon style={{ color: '#545970' }} />
        )}
      </StyledIconButton>
      <Border className="divider" isFocused={focus} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FieldWrapper>
  );
};

export default React.memo(PasswordField);
