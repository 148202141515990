import { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../../../store';
import { setTaskOnRevisionAsync } from '../../../store/slices/taskboardSlice';
import SendIcon from '../../common/icons/SendIcon';
import SendResponseForm from './SendResponseForm';

const ButtonWrapper = styled.div`
  margin-top: 24px;
  position: relative;
`;

const Button = styled.button<{ isOpen: boolean }>`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  background-color: ${({ isOpen }) => (isOpen ? '#73498a' : '#925CB0')};
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;

  span {
    margin-right: 8px;
  }

  :hover {
    background-color: #73498a;
  }
`;

const SetOnRevision = styled.button`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  margin-top: 24px;
  background-color: #448aca;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;

  :hover {
    background-color: #2869a5;
  }
`;

function AssignAuthor({ task }) {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const toogleModal = () => {
    setOpen((prev) => !prev);
  };

  const handleSetOnRevision = async () => {
    await dispatch(
      setTaskOnRevisionAsync({
        taskId: task._id,
      })
    );
  };

  return (
    <ButtonWrapper>
      <Button isOpen={open} onClick={toogleModal}>
        <span>Prepare and send</span>
        <SendIcon />
      </Button>
      {open && <SendResponseForm task={task} close={toogleModal} />}

      <SetOnRevision onClick={handleSetOnRevision}>
        <span>Set on revision</span>
      </SetOnRevision>
    </ButtonWrapper>
  );
}

export default AssignAuthor;
