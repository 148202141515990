import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import TaskCard from '../card/TaskCard';
import { Task } from '../../../store/slices/taskboardSlice';
import { AuthState } from '../../../store/slices/authSlice';
import AnimatedFolder from '../../common/lottie-animations/AnimatedFolder';
import NewTaskButton from './NewTaskButton';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 320px;
  overflow: hidden;
  flex-direction: column;
  margin-right: 24px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 20px;
  min-height: 600px;
  max-height: 800px;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    flex-shrink: 0;
    box-shadow: none;
    max-height: 600px;
  }
`;

const TaskList = styled(motion.div)`
  height: 100%;
  overflow: auto;
  padding: 12px 20px 20px 20px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Heading = styled.header`
  display: flex;
  padding: 20px 20px 8px 20px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #2a2d39;

  @media screen and (max-width: 600px) {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 20px;
    background: #2a2d39;
  }
`;

const Title = styled.h2`
  font-weight: normal;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
`;

const Amount = styled.p`
  color: #545970;
  font-weight: 600;
  line-height: 1;
  font-size: 18px;
  margin-left: auto;
`;

const Util = styled.div`
  width: 100%;
  height: 80px;
  flex-shrink: 0;
`;

const NoTasksFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NoTasksMessage = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin-top: -38px;
`;

const sortTasks = (tasks: Task[], columnId: string | number) => {
  const sortedTasks = tasks.sort((a, b) => {
    if (columnId === 'pending') {
      const dateA = a.pendedAt ?? a.createdAt;
      const dateB = b.pendedAt ?? b.createdAt;

      // eslint-disable-next-line no-nested-ternary
      return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
    }

    // eslint-disable-next-line no-nested-ternary
    return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
  });

  return sortedTasks;
};

const getTasksForManager = (tasks, columnId) => {
  const tasksArray: Task[] = Object.values<Task>(tasks).filter((task) => {
    if (columnId === 'pending' && task.isPending) {
      return true;
    }

    if (columnId === 'to-do' && task.status === 0 && !task.isPending) {
      return true;
    }

    if (
      columnId === 'in-progress' &&
      task.status === 1 &&
      !task.docUrl &&
      !task.isPending
    ) {
      return true;
    }

    if (
      columnId === 'in-review' &&
      task.status === 1 &&
      task.docUrl &&
      !task.isPending
    ) {
      return true;
    }

    if (columnId === 'in-progress' && task.status === 4 && !task.isPending) {
      return true;
    }

    if (columnId === 'ready-to-send' && task.status === 2 && !task.isPending) {
      return true;
    }

    if (columnId === 'in-progress' && task.status === 4 && !task.isPending) {
      return true;
    }

    if (columnId === 'in-review' && task.status === 5 && !task.isPending) {
      return true;
    }

    return false;
  });

  return sortTasks(tasksArray, columnId);
};

const getTasksForAuthor = (tasks, columnId, auth: AuthState) => {
  const tasksArray: Task[] = Object.values<Task>(tasks).filter((task) => {
    if (
      columnId === 'to-do' &&
      task.executor &&
      task.executor._id === auth._id &&
      task.status === 1 &&
      !task.docUrl
    ) {
      return true;
    }

    if (
      columnId === 'in-review' &&
      task.executor &&
      task.executor._id === auth._id &&
      task.status === 1 &&
      task.docUrl
    ) {
      return true;
    }

    if (
      columnId === 'approved' &&
      task.executor &&
      task.executor._id === auth._id &&
      task.status === 2
    ) {
      return true;
    }

    if (
      columnId === 'in-revision' &&
      task.executor &&
      task.executor._id === auth._id &&
      task.status === 4
    ) {
      return true;
    }

    if (
      columnId === 'in-review' &&
      task.executor &&
      task.executor._id === auth._id &&
      task.status === 5
    ) {
      return true;
    }

    return false;
  });

  return sortTasks(tasksArray, columnId);
};

const columns = {
  initial: { opacity: 0, y: -10 },
  animate: (i) => ({
    opacity: 1,
    y: 0,
  }),
};

function Column({
  tasks,
  type,
  auth,
  title,
  columnId,
}: {
  auth: AuthState;
  tasks: { [keyof: string]: Task };
  type: string;
  title: string;
  columnId: string | number;
}) {
  const tasksToRender =
    type === 'user'
      ? getTasksForAuthor(tasks, columnId, auth)
      : getTasksForManager(tasks, columnId);

  if (columnId === 'pending' && tasksToRender.length === 0) {
    return null;
  }

  return (
    <Wrapper
      key={columnId}
      initial="initial"
      animate="animate"
      variants={columns}
    >
      <Heading>
        <Title>{title}</Title>
        {auth.isAdmin && columnId === 'to-do' && <NewTaskButton />}
        <Amount>{tasksToRender.length}</Amount>
      </Heading>
      {(columnId === 0 || columnId === 'to-do') &&
        tasksToRender.length === 0 && (
          <NoTasksFrame>
            <AnimatedFolder />
            <NoTasksMessage>No tasks yet</NoTasksMessage>
          </NoTasksFrame>
        )}
      <TaskList>
        {tasksToRender.map((task) => {
          return <TaskCard auth={auth} key={task._id} task={task} />;
        })}
        <Util />
      </TaskList>
    </Wrapper>
  );
}

export default React.memo(Column);
