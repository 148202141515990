import { Checkbox } from '@material-ui/core';
import { FC } from 'react';
import styled from 'styled-components';

const StyledFilterCheckboxLabel = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledFilterCheckbox = styled(Checkbox)`
  margin: 0;
  padding: 0;
`;

type FilterCheckboxProps = {
  checked: boolean;
  label?: string;
  onClick: (e) => void;
};

export const FilterCheckbox: FC<FilterCheckboxProps> = ({
  checked,
  label,
  onClick,
}) => {
  return (
    <StyledFilterCheckboxLabel onClick={onClick}>
      <StyledFilterCheckbox color="primary" checked={checked} />
      {label}
    </StyledFilterCheckboxLabel>
  );
};
