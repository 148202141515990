export function getTime(value?: string) {
  if (!value) {
    return null;
  }

  const [h, m] = value.split(':').map(Number);

  return {
    hour: h,
    minute: m,
  };
}

export function getTimeISO(value?: string) {
  const t = getTime(value);

  if (!t) {
    return value;
  }

  const day = 24 * 60;
  const offset = new Date().getTimezoneOffset();
  const time = (((t.hour * 60 + t.minute - offset) % day) + day) % day;

  const hour = Math.floor(time / 60);
  const minute = time - hour * 60;

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
}

export function getTimeUTC(value?: string) {
  const t = getTime(value);

  if (!t) {
    return value;
  }

  const day = 24 * 60;
  const offset = new Date().getTimezoneOffset();
  const time = (((t.hour * 60 + t.minute + offset) % day) + day) % day;

  const hour = Math.floor(time / 60);
  const minute = time - hour * 60;

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
}