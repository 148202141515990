import Lottie from 'lottie-react';
import animation from '../../../static/lottie/processing.json';

const lottieIconStyles = {
  width: '220px',
  height: '220px',
};

const AnimatedFolder = () => {
  return <Lottie loop style={lottieIconStyles} animationData={animation} />;
};

export default AnimatedFolder;
