import { useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import Topbar from '../topbar/Topbar';

const Wrapper = styled.div`
  height: 100vh;
`;

const Main = styled.main`
  padding-top: 32px;
  padding-right: 42px;
  margin-left: 144px;

  @media screen and (max-width: 600px) {
    padding-top: 16px;
    margin-left: 16px;
    padding-right: 16px;
  }
`;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const phone = useMediaQuery('(max-width:600px)');
  return (
    <Wrapper>
      {!phone && <Sidebar />}
      <Main>
        <Header phone={phone} />
        {children}
      </Main>
      {phone && <Topbar />}
    </Wrapper>
  );
};

export default Layout;
