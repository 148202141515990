import { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import {
  approveTaskAsync,
  attachFullDocumentAsync,
  setTaskOnRevisionAsync,
} from '../../../store/slices/taskboardSlice';

import { useAppDispatch, useAppSelector } from '../../../store';

import AnimatedProcessing from '../../common/lottie-animations/AnimatedProcessing';
import DocumentLink from './DocumentLink';
import DocumentAttachButton from './DocumentAttachButton';
import AssignProofreading from './AssignProofreading';

const ProcessingWrapper = styled.div`
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    font-weight: 600;
    text-align: center;
    color: #3c8c8a;
  }
`;

const ActionsWrapper = styled.div`
  margin-top: 24px;
  position: relative;
`;

const SuccessMessage = styled(motion.p)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

const SaveActions = styled.div`
  display: flex;
  padding-top: 24px;

  > button:last-of-type {
    flex-grow: 1;
  }
`;

const Approve = styled.button`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  margin-top: 24px;
  background-color: #3c8c8a;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;

  :hover {
    background-color: #2b6261;
  }
`;

const SetOnRevision = styled.button`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  margin-top: 24px;
  background-color: #448aca;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;

  :hover {
    background-color: #2869a5;
  }
`;

const Info = styled.div`
  background-color: #24242e;
  padding: 6px 12px;
  border-radius: 12px;
  margin-top: 8px;
`;

const InfoLabel = styled.span`
  color: #727280;
`;

const Processing = ({ type }) => {
  const title =
    type === 'success' ? (
      <h4>Good job! Take a rest 🙏</h4>
    ) : (
      <h4>Task is in review</h4>
    );
  return (
    <ProcessingWrapper>
      {title}
      <AnimatedProcessing />
    </ProcessingWrapper>
  );
};

const DocumentSection = ({ task }) => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [currentDocLink, setCurrentDocLink] = useState(task.docUrl);
  const [currentDocExtras, setCurrentDocExtras] = useState({
    disease: task.docPlantDisease,
    plant: task.docPlantName,
    location: task.docPlantLocation,
  });

  const [success, setSuccess] = useState(false);

  const saveDocument = (value) => {
    setCurrentDocLink(value.document || '');
    setCurrentDocExtras({
      disease: value.disease || '',
      plant: value.plant || '',
      location: value.location || '',
    });
  };

  const attachDocument = async () => {
    await dispatch(
      attachFullDocumentAsync({
        taskId: task._id,
        docUrl: currentDocLink,
        disease: currentDocExtras.disease,
        plant: currentDocExtras.plant,
        location: currentDocExtras.location,
      })
    );
    setSuccess(true);
  };

  const handleApprove = async () => {
    await dispatch(
      approveTaskAsync({
        taskId: task._id,
      })
    );
  };

  const handleSetOnRevision = async () => {
    await dispatch(
      setTaskOnRevisionAsync({
        taskId: task._id,
      })
    );

    setSuccess(true);
  };

  const isTaskInProcess =
    task.status === 4 || (task.status === 1 && !task.docUrl);
  const isInReview = (task.status === 1 && task.docUrl) || task.status === 5;
  const needSave = currentDocLink !== task.docUrl || currentDocExtras.disease !== task.docPlantDisease || currentDocExtras.plant !== task.docPlantName || currentDocExtras.location !== task.docPlantLocation;
  const canBeReviewed =
    auth.isAdmin &&
    isInReview &&
    task.docUrl === currentDocLink &&
    task.proofreader;

  const needProofreader = auth.isAdmin && isInReview && !task.proofreader;
  const editDocumentAvailable =
    !auth.isAdmin ||
    (!needProofreader && task.proofreader === auth._id) ||
    task.executor._id === auth._id ||
    auth.isSuperAdmin;

  if (!currentDocLink && editDocumentAvailable && !task.docUrl) {
    return <DocumentAttachButton setDocLink={()=>{}} initialValue={task.docUrl} extraValues={currentDocExtras} setDocument={saveDocument} />;
  }

  if (!auth.isAdmin && success) {
    return (
      <ActionsWrapper>
        <DocumentLink
          initialLink={task.docUrl}
          extraValues={currentDocExtras}
          docLink={currentDocLink}
          canEdit={false}
          setDocLink={() => {}}
          setDocument={saveDocument}
        />

        <SuccessMessage
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <Processing type="success" />
        </SuccessMessage>
      </ActionsWrapper>
    );
  }

  return (
    <ActionsWrapper>
      {editDocumentAvailable && (
        <DocumentLink
          initialLink={task.docUrl}
          docLink={currentDocLink}
          extraValues={currentDocExtras}
          canEdit={task.status !== 2 && task.status !== 3}
          setDocLink={() => {}}
          setDocument={saveDocument}
        />
      )}

      {currentDocExtras.plant?.trim() && <Info><InfoLabel>Plant:</InfoLabel> {currentDocExtras.plant}</Info>}
      {currentDocExtras.disease?.trim() && <Info><InfoLabel>Disease:</InfoLabel> {currentDocExtras.disease}</Info>}
      {currentDocExtras.location?.trim() && <Info><InfoLabel>Location:</InfoLabel> {currentDocExtras.location}</Info>}

      {isTaskInProcess && editDocumentAvailable && (
        <SaveActions>
          <Button
            color="primary"
            type="button"
            variant="contained"
            disableElevation
            onClick={() => {
              attachDocument();
            }}
          >
            {currentDocLink === task.docUrl
              ? 'Send to review'
              : 'Save & send to review'}
          </Button>
        </SaveActions>
      )}

      {isInReview && needSave && (
        <SaveActions>
          <Button
            color="primary"
            type="button"
            variant="contained"
            disableElevation
            onClick={() => {
              attachDocument();
            }}
          >
            Save
          </Button>
        </SaveActions>
      )}

      {canBeReviewed && auth._id === task.proofreader && (
        <>
          <Approve onClick={handleApprove}>
            <span>Approve task</span>
          </Approve>

          <SetOnRevision onClick={handleSetOnRevision}>
            <span>Set on revision</span>
          </SetOnRevision>
        </>
      )}

      {needProofreader && (
        <>
          <AssignProofreading taskId={task._id} />
        </>
      )}
    </ActionsWrapper>
  );
};

export default DocumentSection;
