const IMAGE_DOMAIN = 'https://web-images.myplantin.com';

const AVATAR_PLACEHOLDERS = [
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/0.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/1.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/2.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/3.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/4.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/5.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/6.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/7.png`,
  `${IMAGE_DOMAIN}/expert-help/avatar/placeholder/8.png`,
];

const DEFAULT_AVATAR_PLACEHOLDER = AVATAR_PLACEHOLDERS[0];

export { IMAGE_DOMAIN, AVATAR_PLACEHOLDERS, DEFAULT_AVATAR_PLACEHOLDER };
