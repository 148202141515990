import Pagination from '@material-ui/lab/Pagination';
import { useState } from 'react';
import styled from 'styled-components';

interface Column {
  id: string;
  label: string;
  width: string | number;
  executor?: Function;
}

type TableProps<T> = {
  cols: Column[];
  rows: T[];
  onRowClick?: (props: T) => void;
};

const PaginatedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin-bottom: 50px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const headingRowStyle = `
  color: #545970;
  font-weight: 600;
  line-height: 1;
  font-size: 14px;
  padding: 12px 16px;
  text-align: left;
  cursor: pointer;
  align-items: center;
  text-transform: uppercase;
  height: min-content;
`;

const rowStyle = `
  color: #fff;
  text-align: left;
  background-color: #3b4150;
  border-radius: 12px;
  padding: 12px 16px;
  height: min-content;
`;

const getDynamicGrid = (cols: Column[]) => {
  const colsGridTemplate = cols.reduce(
    (acum, col) => acum + `${col.width} `,
    ''
  );

  return styled.div<{ isHeading?: boolean; isClickable?: boolean }>`
    display: grid;
    grid-template-columns: ${colsGridTemplate};
    width: 100%;
    ${({ isClickable }) => {
      return isClickable ? 'cursor: pointer;' : '';
    }}
    ${({ isHeading }) => {
      return isHeading ? headingRowStyle : rowStyle;
    }}
  `;
};

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: center;
  }

  .MuiPaginationItem-root {
    color: #fff;
  }
`;

export const Table = <T extends object>({
  cols,
  rows,
  onRowClick,
}: TableProps<T>) => {
  const Row = getDynamicGrid(cols);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const ITEM_PER_PAGE = 10;

  const paginateItems = (page, items) => {
    if (items.length < ITEM_PER_PAGE) {
      return items;
    }
    const startIndex = (page - 1) * ITEM_PER_PAGE;
    const endIndex = page * ITEM_PER_PAGE;
    return items.slice(startIndex, endIndex);
  };

  const paginatedRows = paginateItems(currentPage, rows);

  const pages = Math.ceil(rows.length / ITEM_PER_PAGE);

  return (
    <PaginatedTableContainer>
      <TableContainer>
        <Row isHeading>
          {cols?.map((col) => (
            <Col>{col.label}</Col>
          ))}
        </Row>
        {paginatedRows?.map((row) => (
          <Row
            isClickable={Boolean(onRowClick)}
            onClick={() => {
              onRowClick && onRowClick(row);
            }}
          >
            {cols?.map((col) => {
              const value = col?.executor ? col?.executor(row) : row?.[col.id];
              return <Col>{value}</Col>;
            })}
          </Row>
        ))}
      </TableContainer>
      {paginatedRows.length > 0 && (
        <StyledPagination
          color="secondary"
          count={pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
    </PaginatedTableContainer>
  );
};
