import { useCallback, useState } from 'react';
import { SearchInput } from '../common/SearchInput';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { GetAllUsersPayload } from '../../store/slices/usersSlice';
import { FilterCheckbox } from '../common/FilterCheckbox';

type UsersFilter = {
  filterState: GetAllUsersPayload;
  handleFiltersChange: (filter: {
    name: string;
    value: string | number | null;
  }) => void;
};

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const UsersFilter = ({ filterState, handleFiltersChange }) => {
  const [search, setSearch] = useState<string>(filterState?.search ?? '');

  const debouncedFiltersChange = useCallback(
    debounce(handleFiltersChange, 500),
    []
  );

  const handleSearchChange = (search: string) => {
    setSearch(search);
    debouncedFiltersChange({ name: 'search', value: search });
  };

  const handleIsDeletedChange = () => {
    handleFiltersChange({ name: 'isDeleted', value: !filterState.isDeleted });
  };

  return (
    <FilterContainer>
      <SearchInput
        value={search}
        onChange={handleSearchChange}
        placeholder="Search for users by name or email"
      />
      <FilterContainer>
        <FilterCheckbox
          checked={filterState.isDeleted ?? false}
          label="Show Deleted"
          onClick={handleIsDeletedChange}
        />
      </FilterContainer>
    </FilterContainer>
  );
};
