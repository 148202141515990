import React, { useState } from 'react';
import styled from 'styled-components';
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';

import { AnimatePresence } from 'framer-motion';
import UploadAvatarPopup from './UploadAvatarPopup';

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const UploadPverlay = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
`;

const Wrapper = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;

  flex-shrink: 0;
  overflow: hidden;
  position: relative;

  :hover {
    ${UploadPverlay} {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    width: 80px;
    height: 80px;
  }
`;

function UserAvatar({ src, user, auth }) {
  const [file, setFile] = useState(null);
  const [popup, setPopup] = useState(false);

  const resetFileInput = (e: any) => {
    if (e.target.value) {
      e.target.value = null;
    }
  };

  const handleFiles = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    e.preventDefault();

    const { files } = e.target;

    if (!files || !files.length) {
      return;
    }

    if (files.length > 1) {
      return null;
    }

    setFile(files[0]);
    setPopup(true);
  };

  const closePopup = () => {
    setPopup(false);
    setFile(null);
  };

  return (
    <>
      <Wrapper>
        {src && <Avatar src={src} />}
        <>
          <UploadPverlay htmlFor="upload-pdf">
            <PhotoCameraRoundedIcon fontSize="large" />
          </UploadPverlay>
          <input
            type="file"
            id="upload-pdf"
            accept="image/*"
            onChange={handleFiles}
            onClick={resetFileInput}
            style={{ display: 'none' }}
          />
        </>
      </Wrapper>
      <AnimatePresence>
        {popup && (
          <UploadAvatarPopup
            file={file}
            userId={user._id}
            onClose={closePopup}
          />
        )}
      </AnimatePresence>
    </>
  );
}

export default UserAvatar;
