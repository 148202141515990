import { useState } from 'react';
import styled from 'styled-components';

import DocumentIcon from '../../common/icons/DocumentIcon';
import AttachDocumentModal from './AttachDocumentModal';

const AttachButton = styled.button<{ isOpen: boolean }>`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  background-color: ${({ isOpen }) => (isOpen ? '#2a6462' : '#3c8c8a')};
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  border: 2px solid transparent;

  span {
    margin-right: 8px;
  }
`;

const DocumentAttachButton = ({ initialValue = '', extraValues = undefined, setDocLink, setDocument = (value) => {} }) => {
  const [openAttachModal, setOpenAttachModal] = useState(false);

  const toggleAttachModal = () => {
    setOpenAttachModal((prev) => !prev);
  };

  const saveDocument = (docLink) => {
    setDocLink(docLink);
    toggleAttachModal();
  };

  const saveFullDocument = (document) => {
    setDocument(document);
    toggleAttachModal();
  };

  return (
    <>
      <AttachButton isOpen={openAttachModal} onClick={toggleAttachModal}>
        <span>Attach document</span>
        <DocumentIcon />
      </AttachButton>

      {openAttachModal && (
        <AttachDocumentModal
          initialValue={initialValue}
          extraValues={extraValues}
          close={toggleAttachModal}
          saveDocument={saveDocument}
          saveFullDocument={saveFullDocument}
        />
      )}
    </>
  );
};

export default DocumentAttachButton;
