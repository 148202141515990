import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import { Task } from '../../../../store/slices/taskboardSlice';
import { useAppDispatch } from '../../../../store';
import { openModal } from '../../../../store/slices/uiSlice';

const colorByStatus = {
  0: '#7F6FFF',
  1: '#3C8C8A',
  2: '#925CB0',
  3: 'var(--primary)',
  4: '#448ACA',
  5: '#448ACA',
};

const statusTitle = {
  0: 'to do',
  1: 'in progress',
  2: 'ready to send',
  3: 'finished',
  4: 'in revision',
  5: 'after revision',
};

const Wrapper = styled(motion.div)`
  padding: 12px;
  position: absolute;
  z-index: 10;
  top: 120%;
  left: 0;
  width: 100%;
  max-height: 330px;
  overflow: auto;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgb(0 0 0 / 29%),
    0px 6.6501px 29.992px rgb(0 0 0 / 19%),
    0px 2.76726px 12.4803px rgb(0 0 0 / 10%);
  border-radius: 20px;
`;

const List = styled.ul``;
const Item = styled.li`
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  justify-content: space-between;
  background-color: var(--black);

  p {
    width: 25%;
    font-size: 14px;
  }

  margin-bottom: 8px;

  :hover {
    border: ${({ color }) => `1px solid ${color}`};
  }

  @media screen and (max-width: 600px) {
    p {
      width: 33.33%;
    }
  }
`;

const RequestId = styled.p`
  width: 20% !important;
`;

const Customer = styled.p`
  white-space: nowrap;
  overflow: hidden;
  width: 30% !important;
  text-overflow: ellipsis;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Status = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20% !important;

  span {
    padding: 2px 8px;
    border-radius: 30px;
    font-size: 12px;
    text-transform: uppercase;
    background-color: ${({ color }) => color};
  }

  @media screen and (max-width: 600px) {
    width: 33.33% !important;
  }
`;

const NoResults = styled.p`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

interface SuggestionsProps {
  suggestions: Task[];
}

const Suggestions: React.FC<SuggestionsProps> = ({ suggestions = [] }) => {
  const dispatch = useAppDispatch();
  const handleItemClick = (task) => () => {
    dispatch(openModal({ type: 'task', props: task }));
  };
  return (
    <Wrapper
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
    >
      {suggestions.length > 0 ? (
        <List>
          {suggestions.map((item) => {
            const color = item.isPending
              ? '#d69f4c'
              : colorByStatus[item.status];
            const statusText = item.isPending
              ? 'pending'
              : statusTitle[item.status];
            return (
              <Item
                key={item._id}
                color={color}
                onClick={handleItemClick(item)}
              >
                <RequestId>{item.requestId}</RequestId>
                <Customer>{item.email}</Customer>
                <p>{dayjs(item.createdAt).format('DD.MM.YY, HH:mm')}</p>
                <Status color={color}>
                  <span>{statusText}</span>
                </Status>
              </Item>
            );
          })}
        </List>
      ) : (
        <NoResults>No results</NoResults>
      )}
    </Wrapper>
  );
};

export default Suggestions;
