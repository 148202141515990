import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../lib/api/axios';

export interface Payment {
  _id: string;
  tasks: string[];
  price: number;
  from: Date;
  to: Date;
  user: string;
}

const initialState: Payment[] = [];

interface ErrorResponse {
  status: number;
  message: string;
}

type CreatePaymentReturnData = Payment;

type CreatePaymentPayload = {
  from: string;
  to: string;
  price: number;
  user: string;
  tasks: string[];
};

interface CreateTaskThunkApi {
  rejectValue: ErrorResponse;
}

export const createPaymentAsync = createAsyncThunk<
  CreatePaymentReturnData,
  CreatePaymentPayload,
  CreateTaskThunkApi
>('payments/createPayment', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'post',
      url: '/payment',
      data: payload,
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type FetchPaymentReturnData = Payment[];

type FetchPaymentPayload = null;

interface FetchTaskThunkApi {
  rejectValue: ErrorResponse;
}

export const fetchPaymentsAsync = createAsyncThunk<
  FetchPaymentReturnData,
  FetchPaymentPayload,
  FetchTaskThunkApi
>('payments/fetchPayments', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'get',
      url: '/payment',
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPaymentAsync.fulfilled, (state, { payload }) => {
      state.push(payload);
    });
    builder.addCase(fetchPaymentsAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

// export const {} = usersSlice.actions;

export default paymentsSlice.reducer;
