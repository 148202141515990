import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { motion } from 'framer-motion';
import { useAppDispatch } from '../../../../store';
import { deleteTaskAsync } from '../../../../store/slices/taskboardSlice';

const Wrapper = styled.div`
  background: #626275;
  padding: 48px 32px 32px 32px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const IconButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const WarningMessage = styled.p`
  margin-top: 10px;
  margin-bottom: 32px;
  font-weight: 600;
`;

interface CreateUserModalProps {
  taskId: string;
  handleClose: () => void;
}

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  > button:last-of-type {
    margin-left: 16px;
  }
`;

const SuccessMessage = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #1f877f;
  border-radius: 8px;
  min-width: 400px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

const DeleteTaskModal: React.FC<CreateUserModalProps> = ({
  taskId,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const deleteTask = async () => {
    setLoading(true);
    dispatch(deleteTaskAsync({ taskId }));
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      handleClose();
    }, 1200);
  };
  return (
    <Wrapper>
      <IconButton onClick={handleClose}>
        <CloseRoundedIcon style={{ color: '#979797' }} />
      </IconButton>
      {success ? (
        <SuccessMessage
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          Task has been deleted <span>👌</span>
        </SuccessMessage>
      ) : (
        <WarningMessage>
          Are you sure you want to permanently delete this task?
        </WarningMessage>
      )}
      {!success && (
        <Actions>
          <Button
            color="primary"
            onClick={handleClose}
            disableElevation
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={deleteTask}
            disableElevation
            variant="contained"
            disabled={loading}
            endIcon={
              loading && (
                <CircularProgress style={{ color: '#fff' }} size={20} />
              )
            }
          >
            {loading ? 'Deleting' : 'Delete'}
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
};

export default DeleteTaskModal;
