import { FC, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../lib/services/images';
import { User } from '../../store/slices/usersSlice';
import { FilterCheckbox } from '../common/FilterCheckbox';

const Select = styled.button`
  text-align: center;
  padding: 8px 16px;
  color: #fff;
  height: 52px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  border: 1px solid #b9375e;

  span {
    margin-right: 8px;
  }
`;

const ExecutorWrapper = styled(Select)`
  position: relative;
  justify-content: space-between;
  width: 240px;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

const StyledUserSelector = styled(motion.ul)`
  cursor: default;
  position: absolute;
  padding: 12px;
  left: 0;
  width: 100%;
  top: 120%;
  border-radius: 8px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 8px;
  overflow: auto;
`;

const UserItem = styled.li`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  :hover {
    background-color: #232530;
  }
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
`;

const Name = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const Selecteduser = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
`;

const SelectAll = styled.p`
  margin-top: 16px;
  background-color: #232530;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
`;

interface UserSelectorProps {
  users: User[];
  selectedUser: User;
  handleSelect: (user: User | null) => () => void;
  name: string;
}

export const UserSelector: FC<UserSelectorProps> = ({
  users,
  name,
  selectedUser,
  handleSelect,
}) => {
  const [userSelectOpen, setUserSelectOpen] = useState(false);
  const [isFilterActivated, setIsFilterActivated] = useState(false);

  const toogleUserSelect = () => {
    setUserSelectOpen((prev) => !prev);
  };

  const toggleCheckbox = (e) => {
    e.stopPropagation();
    setIsFilterActivated((prev) => !prev);
  };

  return (
    <ExecutorWrapper onClick={toogleUserSelect}>
      {selectedUser ? (
        <Selecteduser>
          <Avatar src={selectedUser.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
          <Name>{selectedUser.firstName}</Name>
        </Selecteduser>
      ) : (
        <span>{name}</span>
      )}
      <ExpandMoreIcon />
      {userSelectOpen && (
        <StyledUserSelector
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <FilterCheckbox
            checked={isFilterActivated}
            label="Show Deleted"
            onClick={toggleCheckbox}
          />

          {users.map((user) => {
            if (
              (user.isDeleted && !isFilterActivated) ||
              (!user.isDeleted && isFilterActivated)
            ) {
              return null;
            }

            return (
              <UserItem onClick={handleSelect(user)} key={user._id}>
                <Avatar src={user.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
                <Name>
                  {user.firstName} {user.lastName}
                </Name>
              </UserItem>
            );
          })}
          <SelectAll onClick={handleSelect(null)} key="select all">
            Select All
          </SelectAll>
        </StyledUserSelector>
      )}
    </ExecutorWrapper>
  );
};
