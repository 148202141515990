import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import styled from 'styled-components';

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiInputBase-root {
    color: var(--white);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: var(--primary);
  }

  .MuiIconButton-root {
    color: var(--white);
  }
`;

const DatePickersWrapper = styled.div`
  display: flex;
  margin-left: auto;

  > div:first-child {
    margin-right: 16px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-left: 0;

    > div:first-child {
      margin-bottom: 16px;
      margin-right: 0px;
    }
  }
`;

interface DurationProps {
  startTime: number | null;
  endTime: number | null;
  selectTime: ({
    startTime,
    endTime,
  }: {
    startTime?: number;
    endTime?: number;
  }) => void;
}

const Duration: React.FC<DurationProps> = ({startTime, endTime, selectTime}) => {
  const [fromDate, handleFromDateChange] = useState(startTime && new Date(startTime));
  const [toDate, handleToDateChange] = useState(endTime && new Date(endTime));

  const handleStartDate = (date) => {
    if (!date) {
      selectTime({ startTime: null });
      return;
    }

    const startTime = new Date(
      date.replace(/\//g, '-').split('-').reverse().join('-')
    ).getTime();
    selectTime({ startTime });
  };

  const handleEndDate = (date) => {
    if (!date) {
      selectTime({ endTime: null });
      return;
    }

    const timestamp = new Date(
      date.replace(/\//g, '-').split('-').reverse().join('-')
    ).getTime();
    const endTime = timestamp + 86400000;
    // return timestamp in milliseconds of the next date at 00:00:00
    selectTime({ endTime });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        <DatePickersWrapper>
          <StyledKeyboardDatePicker
            size="small"
            autoOk
            placeholder="DD/MM/YYYY"
            variant="inline"
            inputVariant="outlined"
            label="From"
            format="dd/MM/yyyy"
            value={fromDate}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date, value) => {
              handleStartDate(value);
              handleFromDateChange(date);
            }}
          />
          <StyledKeyboardDatePicker
            size="small"
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="To"
            placeholder="DD/MM/YYYY"
            format="dd/MM/yyyy"
            value={toDate}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date, value) => {
              handleEndDate(value);
              handleToDateChange(date);
            }}
          />
        </DatePickersWrapper>
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

export default Duration;
