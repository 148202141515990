import styled from 'styled-components';
import TaskPopupHeader from './TaskPopupHeader';
import TaskPopupContent from './TaskPopupContent';
import TaskPopupSidebar from './TaskPopupSidebar';
import { useAppSelector } from '../../../store';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  max-height: calc(100% - 64px);
  max-width: 940px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 16px 32px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);

  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;

const ContentOverlay = styled.div`
  width: 100%;
  height: 100%;
`;

const colorByStatus = {
  0: '#7F6FFF',
  1: '#3C8C8A',
  2: '#925CB0',
  3: '#b9375e',
  4: '#448aca',
  5: '#448aca',
};

const Indicator = styled.div`
  content: '';
  position: absolute;
  height: 100px;
  width: 98%;
  left: 1%;
  bottom: 28px;
  border-radius: 20px;
  background: ${({ color }) => color};
  z-index: 0;
  transition: background 0.4s linear;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const IndicatorTwo = styled(Indicator)`
  z-index: -1;
  left: 2%;
  bottom: 20px;
  width: 96%;
`;

const TaskPopupContentContainer = styled.div`
  display: flex;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Util = styled.div`
  height: 40px;
  flex-shrink: 0;
`;

function Task({ task, handleClose }) {
  const auth = useAppSelector((state) => state.auth);
  const color = task.isPending ? '#d69f4c' : colorByStatus[task.status];

  const isRestricted =
    task.executor && auth._id !== task.executor._id && !auth.isAdmin;

  return (
    <Wrapper>
      <Content>
        <ContentOverlay>
          <TaskPopupHeader task={task} handleClose={handleClose} />

          <TaskPopupContentContainer>
            <TaskPopupContent task={task} isRestricted={isRestricted} />
            {!isRestricted && (
              <TaskPopupSidebar task={task} handleClose={handleClose} />
            )}
          </TaskPopupContentContainer>
        </ContentOverlay>
      </Content>
      <Indicator color={color} />
      <IndicatorTwo color={color} />
      <Util />
    </Wrapper>
  );
}

export default Task;
