import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../lib/services/images';
import { useAppSelector } from '../../../store';
import ChatIcon from '../../common/icons/ChatIcon';
import DocumentIcon from '../../common/icons/DocumentIcon';

const Wrapper = styled.div`
  width: 100%;
  color: #fff;
  margin-bottom: 16px;
  position: relative;
  transition: transform 0.2s ease;

  @media screen and (min-width: 600px) {
    :hover {
      transform: scale(1.04);
    }

    :active {
      transform: scale(0.98);
    }
  }
`;

const Container = styled.div`
  padding: 16px;
  width: 100%;
  min-height: 100px;
  border-radius: 12px;
  cursor: pointer;

  background: ${({ color }) => color};
`;

const Typography = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 8px;
`;

const Id = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 16px;
`;
const TaskInfo = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Date = styled.p`
  color: #ffffff;
  opacity: 0.5;
`;

const ReadyToReviewIndicator = styled.div`
  position: absolute;
  bottom: 12px;
  right: 20px;
`;

const getColorsByStatus = (status, isAdmin, docUrl) => {
  if (isAdmin) {
    const adminColors = {
      0: '#7F6FFF',
      1: '#3C8C8A',
      2: '#925CB0',
      4: '#448ACA',
      5: '#448ACA',
    };

    return adminColors[status];
  }

  const colors = {
    0: '#7F6FFF',
    1: docUrl ? '#3C8C8A' : '#3C8C8A',
    2: '#925CB0',
    4: '#448ACA',
    5: '#448ACA',
  };

  return colors[status];
};

const AvatarContainer = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Avatar = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 11px;
  line-height: 14px;
  color: #fff;
`;

const Indicators = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 18px;
  right: 16px;

  svg {
    margin-left: 4px;
  }
`;

function TaskCard({ task, auth }) {
  const { executor } = task;
  const users = useAppSelector((state) => state.users);
  const proofreader = users.find((user) => user._id === task.proofreader);

  const executorShown = task.executor && executor && auth.isAdmin;
  const proofreaderShown = task.proofreader && proofreader && auth.isAdmin;

  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _id, requestId, email, location } = task;
  const handleClick = () => {
    history.push(`/?task=${_id}`);
  };

  return (
    <Wrapper onClick={handleClick}>
      <Container
        color={
          task.isPending
            ? '#d69f4c'
            : getColorsByStatus(task.status, auth.isAdmin, task.docUrl)
        }
      >
        <Id>id {requestId}</Id>
        <TaskInfo>{email}</TaskInfo>
        <TaskInfo>{location}</TaskInfo>
        <Date>{dayjs(task.createdAt).format('DD.MM.YY, HH:mm')}</Date>
      </Container>
      <Indicators>
        {task.docUrl && task.status === 4 && <DocumentIcon />}
        {task.comments && task.comments.length > 0 && <ChatIcon />}
      </Indicators>
      <AvatarContainer>
        {executorShown && (
          <Tooltip
            key={`user_${executor._id}`}
            placement="bottom"
            title={`Author: ${executor.firstName} ${executor.lastName}`}
          >
            <Avatar src={executor.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
          </Tooltip>
        )}
        {proofreaderShown && (
          <Tooltip
            key={`user_${proofreader._id}_task_${task._id}`}
            placement="bottom"
            title={`Proofreader: ${proofreader.firstName} ${proofreader.lastName}`}
          >
            <Avatar src={proofreader.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
          </Tooltip>
        )}
      </AvatarContainer>
    </Wrapper>
  );
}

export default React.memo(TaskCard);
