import { useState } from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import {
  AVATAR_PLACEHOLDERS,
  DEFAULT_AVATAR_PLACEHOLDER,
} from '../../../../lib/services/images';
import TextField from '../../forms/TextField';
import { Actions, StyledButton } from './styled-components';

const Form = styled.form`
  min-width: 350px;
  > div {
    margin-bottom: 18px;
  }
`;

const Placegolders = styled.nav`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 24px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const AvatarButton = styled.button<{ isSelected: boolean }>`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  cursor: pointer;
  margin-right: 8px;
  padding: 6px;
  border-radius: 8px;
  transition: background-color 0.2s;

  :hover {
    background-color: #24242e;
  }

  background-color: ${({ isSelected }) =>
    isSelected && 'rgb(185 56 94 / 49%)'} !important;
`;

const Avatar = styled.img`
  width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const initialErrorState = {
  firstName: '',
  lastName: '',
  email: '',
  telegramLogin: '',
};

function StepOne({ initialValues, handleStepSubmit }) {
  const [values, setValues] = useState({
    avatar: DEFAULT_AVATAR_PLACEHOLDER,
    firstName: initialValues.firstName,
    lastName: initialValues.lastName,
    email: initialValues.email,
    telegramLogin: initialValues.telegramLogin,
    isAdmin: initialValues.isAdmin,
  });
  const [errors, setErrors] = useState(initialErrorState);

  const { avatar, firstName, lastName, email, telegramLogin, isAdmin } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(initialErrorState);

    if (!firstName) {
      setErrors((prev) => {
        return { ...prev, firstName: 'First name is required' };
      });

      return null;
    }

    if (!email) {
      setErrors((prev) => {
        return { ...prev, email: 'Email is required' };
      });

      return null;
    }

    if (!telegramLogin) {
      setErrors((prev) => {
        return { ...prev, telegramLogin: 'Telegram login is required' };
      });

      return null;
    }

    handleStepSubmit({
      avatar,
      firstName,
      lastName,
      email,
      isAdmin,
      telegramLogin:
        telegramLogin && telegramLogin[0] === '@'
          ? telegramLogin.slice(1)
          : telegramLogin,
    });
  };

  const handleAvatarChange = (src) => () => {
    setValues((previousState) => {
      return { ...previousState, avatar: src };
    });
  };

  const handleChange = (target) => {
    setValues((previousState) => {
      return { ...previousState, [target.name]: target.value };
    });
  };

  const handleCheckboxChange = (e) => {
    setValues((previousState) => {
      return { ...previousState, [e.target.name]: e.target.checked };
    });
  };

  return (
    <>
      <Placegolders>
        {AVATAR_PLACEHOLDERS.map((src) => {
          return (
            <AvatarButton
              key={src}
              onClick={handleAvatarChange(src)}
              isSelected={src === avatar}
            >
              <Avatar src={src} />
            </AvatarButton>
          );
        })}
      </Placegolders>
      <Form onSubmit={handleSubmit}>
        <TextField
          required
          type="text"
          label="First name"
          name="firstName"
          onChange={handleChange}
          placeholder="Jhon"
          inputProps={{
            autoCorrect: 'off',
            autoComplete: 'off',
            autoFocus: true,
          }}
          initialValue={firstName}
          error={errors.firstName}
        />
        <TextField
          type="text"
          label="Last name"
          name="lastName"
          onChange={handleChange}
          placeholder="Doe"
          inputProps={{
            autoCorrect: 'off',
            autoComplete: 'off',
          }}
          initialValue={lastName}
          error={errors.lastName}
        />
        <TextField
          type="email"
          label="Email"
          name="email"
          onChange={handleChange}
          placeholder="example@gmail.com"
          inputProps={{
            autoComplete: 'off',
          }}
          initialValue={email}
          error={errors.email}
        />
        <TextField
          type="text"
          required
          label="Telegram login"
          name="telegramLogin"
          onChange={handleChange}
          placeholder="JhonDoe"
          inputProps={{
            autoComplete: 'off',
          }}
          initialValue={telegramLogin}
          error={errors.telegramLogin}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleCheckboxChange}
                checked={isAdmin}
                inputProps={{ name: 'isAdmin' }}
              />
            }
            label="Is Manager"
          />
        </FormGroup>
        <Actions>
          <StyledButton
            type="submit"
            color="primary"
            disableElevation
            variant="contained"
          >
            Next
          </StyledButton>
        </Actions>
      </Form>
    </>
  );
}

export default StepOne;
