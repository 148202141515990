import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { Task } from '../../../../store/slices/taskboardSlice';
import Suggestions from './Suggestions';
import axios from '../../../../lib/api/axios';
import debounce from 'lodash.debounce';
import { SearchInput } from '../../SearchInput';

const Wrapper = styled.div`
  position: relative;
  max-width: 700px;
  flex-grow: 1;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Form = styled.form`
  background: #2a2d38;
  border-radius: 16px;
  width: 100%;
  padding: 18px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 8px;
  }
`;

const getItemsBySearch = (search: string, tasks: Task[]): Task[] => {
  if (!search) {
    return [];
  }
  const result = [];
  // const trimmedSearch = search.trim().toLowerCase().replace(/\s/g, '');
  for (let i = 0; i < tasks.length; i += 1) {
    const task = tasks[i];
    if (
      task.requestId.includes(search) ||
      task.email.includes(search.toLowerCase()) ||
      task.docPlantDisease?.toLowerCase().includes(search.toLowerCase()) ||
      task.docPlantLocation?.toLowerCase().includes(search.toLowerCase()) ||
      task.docPlantName?.toLowerCase().includes(search.toLowerCase())
    ) {
      result.push(task);
    }
  }
  return result.length > 0 ? result : [];
};

function Search() {
  const wrapperRef = useRef(null);
  const [tasks, setTasks] = useState([]);
  const [value, setValue] = useState('');
  const onSubmit = (e) => {
    e.preventDefault();
  };

  const performSearch = useCallback(
    debounce((value) => {
      const execute = async (val) => {
        if (!val) {
          setTasks([]);
          return;
        }
        const response = await axios({
          method: 'get',
          url: '/task/archive',
          params: {
            value: val,
            status: 'all',
          },
        });
        setTasks(response.data.data);
      };
      execute(value).then();
    }, 500),
    []
  );

  useEffect(() => {
    performSearch(value);
  }, [value]);

  const clickListener = useCallback(
    (e) => {
      if (
        value.length > 0 &&
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setValue('');
      }
    },
    [value]
  );

  const escapeListener = useCallback((e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setValue('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escapeListener);

    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, [clickListener, escapeListener]);

  const suggestions = getItemsBySearch(value, tasks);

  return (
    <Wrapper ref={wrapperRef}>
      <Form onSubmit={onSubmit}>
        <SearchInput
          value={value}
          placeholder="Search for tasks"
          customWrapper
          onChange={setValue}
        />
      </Form>
      <AnimatePresence>
        {value.length > 0 && <Suggestions suggestions={suggestions} />}
      </AnimatePresence>
    </Wrapper>
  );
}

export default Search;
