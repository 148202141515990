import { motion } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { closeModal } from '../../../store/slices/uiSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import CreateUserModal from './create-user/CreateUserModal';
import DeleteTaskModal from './delete-task/DeleteTaskModal';
import Task from '../../task-board/popup/Task';
import CreateTaskModal from './create-task/CreateTaskModal';
import ChangeAuthorModal from './change-author/ChangeAuthorModal';
import EditTaskModal from './edit-task/EditTaskModal';
import ChangeProofreaderModal from './change-proofreader/ChangeProofreaderModal';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 99;
  padding: 2rem 1rem;
  perspective: 1000px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Container = styled(motion.div)`
  position: relative;
  margin-bottom: 2rem;
  max-height: calc(100% - 64px);
  max-width: 940px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const ModalManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.ui.modal);

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [modal, handleClose]);

  const handlePropagation = (
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    e.stopPropagation();
  };

  const renderModalByType = () => {
    switch (modal.type) {
      case 'task':
        return <Task task={modal.props} handleClose={handleClose} />;
      case 'create-task':
        return <CreateTaskModal handleClose={handleClose} />;
      case 'edit-task':
        return (
          <EditTaskModal handleClose={handleClose} task={modal.props.task} />
        );
      case 'change-author':
        return (
          <ChangeAuthorModal
            handleClose={handleClose}
            taskId={modal.props.taskId}
          />
        );
      case 'change-proofreader':
        return (
          <ChangeProofreaderModal
            handleClose={handleClose}
            taskId={modal.props.taskId}
          />
        );
      case 'delete-task':
        return (
          <DeleteTaskModal
            taskId={modal.props.taskId}
            handleClose={handleClose}
          />
        );
      case 'create-user':
        return <CreateUserModal handleClose={handleClose} modal={modal} />;
      default:
        throw Error('no such modal type in Modal Manager');
    }
  };

  if (!modal.type) return null;

  return (
    <Backdrop
      onTouchStartCapture={handlePropagation}
      onTouchMoveCapture={handlePropagation}
    >
      <Container
        initial={{ y: -15 }}
        animate={{ y: 0 }}
        exit={{ y: 20 }}
        onClick={handlePropagation}
        onTouchStart={handlePropagation}
      >
        <>{renderModalByType()}</>
      </Container>
    </Backdrop>
  );
};

export default ModalManager;
