import { FC, useState } from 'react';
import { Table } from '../common/Table';
import { User } from '../../store/slices/usersSlice';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../lib/services/images';
import { useHistory } from 'react-router-dom';

type UserTableProps = {
  users: User[];
};

const COLS = [
  {
    id: 'image',
    label: 'Avatar',
    width: '15%',
    executor: ({ firstName, lastName, avatar }: Partial<User>) => {
      return (
        <img
          className="avatar"
          alt={`${firstName} ${lastName}`}
          src={avatar || DEFAULT_AVATAR_PLACEHOLDER}
        />
      );
    },
  },
  {
    id: 'name',
    label: 'Name',
    width: '1fr',
    executor: ({ firstName, lastName }: Partial<User>) => {
      return `${firstName} ${lastName}`;
    },
  },
  { id: 'email', label: 'Email', width: '1fr' },
  {
    id: 'role',
    label: 'Role',
    width: '10%',
    executor: ({ isAdmin = false, isSuperAdmin = false }: Partial<User>) => {
      if (isSuperAdmin) return 'Super Admin';
      if (isAdmin) return 'Admin';
      return 'User';
    },
  },
];

export const UsersTable: FC<UserTableProps> = ({ users }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);

  const onUserClick = (user: User) => {
    history.push(`user/${user._id}`);
  };

  return <Table<User> onRowClick={onUserClick} cols={COLS} rows={users} />;
};
