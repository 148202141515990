import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { motion } from 'framer-motion';
import { CircularProgress } from '@material-ui/core';
import ImageFrames from './ImageFrames';
import axios from '../../../../lib/api/axios';

const Wrapper = styled.div`
  background: #626275;
  padding: 48px 24px 32px 24px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const IconButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const Form = styled.form``;
const Field = styled.div`
  margin-bottom: 12px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  color: var(--accent);
`;

const Input = styled.input`
  display: block;
  min-width: 400px;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #242424;
  border-radius: 8px;
  color: #f3f3f3;
  outline: none;
  border: 2px solid transparent;

  :focus {
    border: 2px solid var(--primary);
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Message = styled.textarea`
  width: 100%;
  outline: none;
  font-size: 1rem;
  resize: vertical;
  margin-bottom: 1rem;
  font-family: inherit;
  min-height: 150px;
  padding: 12px;
  font-size: 16px;
  display: block;
  border-radius: 8px;
  outline: none;
  background-color: #242424;
  color: #fff;
  border: 2px solid transparent;

  :focus {
    border: 2px solid var(--primary);
  }
`;

interface CreateTaskModalProps {
  handleClose: () => void;
}

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  > button:last-of-type {
    margin-left: 16px;
  }
`;

const SuccessMessage = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  background-color: #1f877f;
  border-radius: 8px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

const ErrorMessage = styled(SuccessMessage)`
  background: #e7573d;
  margin-bottom: 24px;
`;

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({ handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({
    email: '',
    requestId: '',
    message: '',
    plantName: '',
    plantAge: '',
    plantLocation: '',
    lastRepot: '',
    lastWater: '',
    fertilizer: '',
    questionType: '',
  });

  const [images, setImages] = useState<{ [key: string]: File }>({});

  const {
    email,
    requestId,
    message,
    plantName,
    plantAge,
    plantLocation,
    lastRepot,
    lastWater,
    fertilizer,
  } = values;

  const handleChange = (e) => {
    const { target } = e;
    setValues((previousState) => {
      return { ...previousState, [target.name]: target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      setError('');
    }

    if (Object.keys(images).length === 0) {
      setError('Upload at least one photo!');
    } else {
      setLoading(true);
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });

      Object.entries(images).forEach(([index, value]) => {
        if (value) {
          formData.append(`image-${index}`, value);
        }
      });

      try {
        await axios({
          method: 'post',
          url: `/task/create`,
          data: formData,
        });

        setSuccess(true);
        setTimeout(() => {
          handleClose();
        }, 1200);
      } catch (err) {
        if (err?.response?.status === 409) {
          setError('Task with such id already exist');
        } else {
          setError('Something goes wrong');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let unmounted = false;
    if (success) {
      setTimeout(() => {
        if (!unmounted) {
          setSuccess(false);
        }
      }, 1200);
    }
    return () => {
      unmounted = true;
    };
  }, [success]);

  return (
    <Wrapper>
      <IconButton onClick={handleClose}>
        <CloseRoundedIcon style={{ color: 'var(--accent)' }} />
      </IconButton>
      <Form onSubmit={handleSubmit}>
        <Field>
          <Label htmlFor="create-task-email">Customer email *</Label>
          <Input
            required
            autoFocus
            name="email"
            type="email"
            value={email}
            autoComplete="off"
            id="create-task-email"
            onChange={handleChange}
          />
        </Field>
        <Field>
          <Label htmlFor="create-task-requestId">Request ID *</Label>
          <Input
            required
            type="text"
            name="requestId"
            value={requestId}
            autoComplete="off"
            id="create-task-requestId"
            onChange={handleChange}
          />
        </Field>
        <Field>
          <Label htmlFor="create-task-plant-name">Plant species</Label>
          <Input
            type="text"
            name="plantName"
            value={plantName}
            autoComplete="off"
            onChange={handleChange}
            id="create-task-plant-name"
          />
        </Field>
        <Field>
          <Label htmlFor="create-task-message">Message*</Label>
          <Message
            required
            name="message"
            value={message}
            autoComplete="off"
            id="create-task-message"
            onChange={handleChange}
          />
        </Field>
        <ImageFrames setImages={setImages} />
        <Field>
          <Label htmlFor="create-task-plant-age">Plant Age</Label>
          <Input
            name="plantAge"
            value={plantAge}
            autoComplete="off"
            id="create-task-plant-age"
            onChange={handleChange}
          />
        </Field>
        <Field>
          <Label htmlFor="create-task-plant-location">Plant Location</Label>
          <Input
            name="plantLocation"
            value={plantLocation}
            autoComplete="off"
            id="create-task-plant-location"
            onChange={handleChange}
          />
        </Field>
        <Field>
          <Label htmlFor="create-task-last-watering">Watering Frequency</Label>
          <Input
            name="lastWater"
            value={lastWater}
            autoComplete="off"
            id="create-task-last-watering"
            onChange={handleChange}
          />
        </Field>
        <Field>
          <Label htmlFor="create-task-last-repot">Last Repot</Label>
          <Input
            name="lastRepot"
            value={lastRepot}
            autoComplete="off"
            id="create-task-last-repot"
            onChange={handleChange}
          />
        </Field>
        <Field>
          <Label htmlFor="create-task-fertilizer">Fertilizer</Label>
          <Input
            name="fertilizer"
            value={fertilizer}
            autoComplete="off"
            id="create-task-fertilizer"
            onChange={handleChange}
          />
        </Field>
        {error && (
          <ErrorMessage
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            {error}
          </ErrorMessage>
        )}
        {success ? (
          <SuccessMessage
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            Task has been created <span>🤘</span>
          </SuccessMessage>
        ) : (
          <Actions>
            <Button
              variant="text"
              type="button"
              color="primary"
              disableElevation
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              endIcon={
                loading && (
                  <CircularProgress style={{ color: '#fff' }} size={20} />
                )
              }
            >
              {loading ? 'Creating' : 'Create'}
            </Button>
          </Actions>
        )}
      </Form>
    </Wrapper>
  );
};

export default CreateTaskModal;
