import React from 'react';

function HideIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 6.5998C8.65511 6.5998 7.56112 7.67574 7.56112 9.00074C7.56112 9.39154 7.66712 9.76364 7.84712 10.1013L11.1191 6.88035C10.7771 6.70415 10.3951 6.5998 10.0001 6.5998ZM17.3851 0.711153C17.6711 0.429616 18.1341 0.429616 18.4201 0.711153C18.7061 0.992689 18.7061 1.44846 18.4201 1.73L2.61512 17.2884C2.47212 17.4291 2.28512 17.5 2.09812 17.5C1.91012 17.5 1.72312 17.4291 1.58012 17.2884C1.29412 17.0068 1.29412 16.551 1.58012 16.2705L6.77612 11.1546C6.33712 10.5246 6.09812 9.78136 6.09812 9.00074C6.09812 6.88232 7.84812 5.15963 10.0001 5.15963C10.7871 5.15963 11.5531 5.39785 12.1901 5.82508L14.2241 3.82381C11.3561 1.98693 7.85912 2.14443 5.12712 4.27368C3.68412 5.39096 2.44512 7.02112 1.53212 9.00172C1.93812 9.88669 2.41012 10.7028 2.93612 11.4312C3.17012 11.7551 3.09312 12.2049 2.76412 12.4353C2.63512 12.5259 2.48712 12.5692 2.34012 12.5692C2.11112 12.5692 1.88612 12.4648 1.74312 12.266C1.10112 11.3771 0.534125 10.374 0.0591255 9.28424C-0.0198744 9.10312 -0.0198744 8.89639 0.0601255 8.71526C1.08112 6.38816 2.51912 4.46071 4.22112 3.14261C7.51912 0.574322 11.8511 0.447335 15.2771 2.78724L17.3851 0.711153ZM19.94 8.71418C19.407 7.50436 18.771 6.40971 18.047 5.46371C17.803 5.14575 17.345 5.08177 17.023 5.32097C16.699 5.56117 16.635 6.01202 16.878 6.32998C17.475 7.1106 18.009 8.00739 18.468 9.00064C16.592 13.0662 13.445 15.4829 10 15.4829C9.22001 15.4829 8.44401 15.3579 7.69402 15.1118C7.30902 14.9887 6.89602 15.1895 6.76902 15.5675C6.64102 15.9446 6.84802 16.3521 7.23102 16.4791C8.13002 16.7744 9.06201 16.924 10 16.924C14.127 16.924 17.843 14.0683 19.94 9.28513C20.02 9.104 20.02 8.89629 19.94 8.71418ZM12.3907 9.42757C12.2127 10.4021 11.4287 11.1759 10.4387 11.3521C10.0407 11.4239 9.77671 11.799 9.84871 12.1908C9.91271 12.5383 10.2217 12.7814 10.5677 12.7814C10.6117 12.7814 10.6557 12.7775 10.6997 12.7706C12.2877 12.4861 13.5467 11.2457 13.8307 9.68253C13.9027 9.28976 13.6377 8.91569 13.2397 8.84579C12.8447 8.77492 12.4617 9.03775 12.3907 9.42757Z"
        fill="white"
      />
    </svg>
  );
}

export default HideIcon;
