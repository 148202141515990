import React from 'react';
import styled from 'styled-components';
import PdfIcon from '../../common/icons/PdfIcon';

const DocLinkWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  background-color: #24242e;
  padding: 12px;
  border-radius: 12px;

  svg {
    flex-shrink: 0;
    width: 22px;
  }
`;

const DocLink = styled.a`
  margin-left: 12px;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
`;

function UploadedPdf({ task }) {
  return (
    <DocLinkWrapper>
      <PdfIcon />
      <DocLink target="_blank" href={task.pdfUrl}>
        {task.pdfUrl}
      </DocLink>
    </DocLinkWrapper>
  );
}

export default UploadedPdf;
