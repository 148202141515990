import { useState } from 'react';
import styled from 'styled-components';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { motion, AnimateSharedLayout } from 'framer-motion';
import Button from '@material-ui/core/Button';
import Modal from '../common/modals/Modal';
import { deleteUserAsync } from '../../store/slices/usersSlice';
import { useAppDispatch } from '../../store';

const ModalContent = styled(motion.div)`
  background: #626275;
  padding: 48px 32px 32px 32px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const WarningMessage = styled.p`
  margin-bottom: 32px;
  font-weight: 600;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  > button:last-of-type {
    margin-left: 16px;
  }
`;

const SuccessMessage = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #1f877f;
  border-radius: 8px;
  min-width: 400px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

function DeleteUser({ userId }) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const toogleModal = () => {
    setOpen((prev) => !prev);
  };
  const deleteuser = async () => {
    setLoading(true);
    await dispatch(deleteUserAsync({ userId }));
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setOpen(false);
    }, 1200);
  };
  return (
    <>
      <Button
        color="secondary"
        onClick={toogleModal}
        disableElevation
        variant="contained"
        startIcon={
          <DeleteRoundedIcon style={{ color: '#cecece' }} fontSize="small" />
        }
      >
        Delete user
      </Button>
      {open && (
        <Modal>
          <AnimateSharedLayout>
            <ModalContent layout>
              {success ? (
                <SuccessMessage
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                >
                  User has been deleted <span>👌</span>
                </SuccessMessage>
              ) : (
                <>
                  <WarningMessage>
                    Are you sure you want to permanently delete this author?
                  </WarningMessage>

                  <Actions>
                    <Button
                      color="primary"
                      onClick={toogleModal}
                      disableElevation
                      variant="text"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={deleteuser}
                      disableElevation
                      variant="contained"
                      disabled={loading}
                      endIcon={
                        loading && (
                          <CircularProgress
                            style={{ color: '#fff' }}
                            size={20}
                          />
                        )
                      }
                    >
                      {loading ? 'Deleting' : 'Delete'}
                    </Button>
                  </Actions>
                </>
              )}
            </ModalContent>
          </AnimateSharedLayout>
        </Modal>
      )}
    </>
  );
}

export default DeleteUser;
