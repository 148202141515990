/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import socketMiddleware from './middlewares/websocket';
// reducers
import taskboardReducer from './slices/taskboardSlice';
import usersReducer from './slices/usersSlice';
import authReducer from './slices/authSlice';
import paymentsReducer from './slices/paymentsSlice';
import uiReducer from './slices/uiSlice';

const store = configureStore({
  reducer: {
    taskboard: taskboardReducer,
    users: usersReducer,
    auth: authReducer,
    payments: paymentsReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(socketMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
