import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { motion } from 'framer-motion';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageFrames from './ImageFrames';
import axios from '../../../../lib/api/axios';
import { Task } from '../../../../store/slices/taskboardSlice';

const Wrapper = styled.div`
  background: #626275;
  padding: 48px 24px 32px 24px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 9, 11, 0.9);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px;
`;

const IconButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const Form = styled.form``;
const Field = styled.div`
  margin-bottom: 12px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  color: var(--accent);
`;

const Input = styled.input`
  display: block;
  min-width: 400px;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #242424;
  border-radius: 8px;
  color: #f3f3f3;
  outline: none;
  border: 2px solid transparent;

  :focus {
    border: 2px solid var(--primary);
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Message = styled.textarea`
  width: 100%;
  outline: none;
  font-size: 1rem;
  resize: vertical;
  margin-bottom: 1rem;
  font-family: inherit;
  min-height: 150px;
  padding: 12px;
  font-size: 16px;
  display: block;
  border-radius: 8px;
  outline: none;
  background-color: #242424;
  color: #fff;
  border: 2px solid transparent;

  :focus {
    border: 2px solid var(--primary);
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  > button:last-of-type {
    margin-left: 16px;
  }
`;

const SuccessMessage = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  background-color: #1f877f;
  border-radius: 8px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

const ErrorMessage = styled(SuccessMessage)`
  background: #e7573d;
  margin-bottom: 24px;
`;

interface EditTaskModalProps {
  task: Task;
  handleClose: () => void;
}

const EditTaskModal: React.FC<EditTaskModalProps> = ({ task, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({
    message: task.message || '',
    plantName: task.plantName || '',
  });
  const [images, setImages] = useState<{ [key: string]: File }>({});

  const { message, plantName } = values;

  const handleChange = (e) => {
    const { target } = e;
    setValues((previousState) => {
      return { ...previousState, [target.name]: target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      setError('');
    }

    setLoading(true);
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    Object.entries(images).forEach(([index, value]) => {
      const realIndex = Number(index + task.images.length);
      if (value) {
        formData.append(`image-${realIndex}`, value);
      }
    });

    try {
      await axios({
        method: 'put',
        url: `/task/edit/${task._id}`,
        data: formData,
      });
      setSuccess(true);

      setTimeout(() => {
        handleClose();
      }, 1200);
    } catch (err) {
      setError('Something goes wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let unmounted = false;
    if (success) {
      setTimeout(() => {
        if (!unmounted) {
          setSuccess(false);
        }
      }, 1200);
    }
    return () => {
      unmounted = true;
    };
  }, [success]);

  return (
    <Wrapper>
      {(loading || success) && (
        <Overlay>
          {loading ? (
            <CircularProgress size={50} style={{ color: 'var(--primary)' }} />
          ) : (
            <SuccessMessage
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              Task has been changed <span>🤘</span>
            </SuccessMessage>
          )}
        </Overlay>
      )}
      <IconButton onClick={handleClose}>
        <CloseRoundedIcon style={{ color: 'var(--accent)' }} />
      </IconButton>
      <Form onSubmit={handleSubmit}>
        <Field>
          <Label htmlFor="edit-task-plant-name">Plant name</Label>
          <Input
            type="text"
            name="plantName"
            value={plantName}
            autoComplete="off"
            onChange={handleChange}
            id="edit-task-plant-name"
          />
        </Field>
        <Field>
          <Label htmlFor="edit-task-message">Message*</Label>
          <Message
            name="message"
            value={message}
            autoComplete="off"
            id="edit-task-message"
            onChange={handleChange}
          />
        </Field>
        <ImageFrames images={task.images} setImages={setImages} />
        {error && (
          <ErrorMessage
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            {error}
          </ErrorMessage>
        )}
        <Actions>
          <Button
            variant="text"
            type="button"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            endIcon={
              loading && (
                <CircularProgress style={{ color: '#fff' }} size={20} />
              )
            }
          >
            {loading ? 'Saving' : 'Save changes'}
          </Button>
        </Actions>
      </Form>
    </Wrapper>
  );
};

export default EditTaskModal;
