import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Actions = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  min-width: 100px;

  :last-of-type {
    margin-left: 16px;
  }
`;
