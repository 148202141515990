import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAppDispatch } from '../../../../store';
import { createUserAsync } from '../../../../store/slices/usersSlice';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuccessMessage = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #1f877f;
  border-radius: 8px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

function FinalStep({ values, handleClose }) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const createUser = async () => {
      await dispatch(createUserAsync(values));
      setTimeout(() => {
        setLoading(false);
        setTimeout(() => {
          handleClose();
        }, 1000);
      }, 1000);
    };
    createUser();
  }, [values, handleClose, dispatch]);

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress color="primary" size={42} />
      ) : (
        <SuccessMessage
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          User has been created <span>👌</span>
        </SuccessMessage>
      )}
    </Wrapper>
  );
}

export default FinalStep;
