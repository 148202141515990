import { useState } from 'react';
import styled from 'styled-components';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import PasswordField from '../../forms/PasswordField';
import { Actions, StyledButton } from './styled-components';

const Form = styled.form`
  min-width: 350px;
  > div {
    margin-bottom: 18px;
  }
`;

const ErorrMessage = styled.p`
  padding: 16px;
  background: var(--error);
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;

  > span:first-child {
    margin-right: 8px;
  }
`;

function StepThree({ values, handleBack, handleStepSubmit }) {
  const [fields, setFields] = useState({
    password: { value: values.password, error: '' },
    confirmPassword: { value: values.password, error: '' },
  });
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = fields;

    if (!password.value || !confirmPassword.value) {
      return setError('Fill both fields');
    }

    if (password.value !== confirmPassword.value) {
      return setError('Passwords are not the same');
    }

    handleStepSubmit({
      password: password.value,
    });
  };

  const handleChange = (target) => {
    if (error) {
      setError('');
    }

    setFields((state) => {
      const previous = state[target.name];
      return { ...state, [target.name]: { ...previous, value: target.value } };
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PasswordField
        label="Password"
        name="password"
        onChange={handleChange}
        inputProps={{
          autoComplete: 'new-password',
          autoFocus: true,
        }}
        initialValue={fields.password.value}
      />
      <PasswordField
        label="Confirm Password"
        name="confirmPassword"
        onChange={handleChange}
        inputProps={{
          autoComplete: 'new-password',
        }}
        initialValue={fields.confirmPassword.value}
      />
      {error && (
        <ErorrMessage>
          <span>
            <WarningRoundedIcon style={{ color: '#fff' }} />
          </span>
          <span>{error}</span>
        </ErorrMessage>
      )}
      <Actions>
        <StyledButton
          type="button"
          variant="text"
          color="primary"
          disableElevation
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          type="submit"
          color="primary"
          disableElevation
          variant="contained"
        >
          Create user
        </StyledButton>
      </Actions>
    </Form>
  );
}

export default StepThree;
