import { useState } from 'react';

import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

import AttachDocumentModal from './AttachDocumentModal';

import DocumentIcon from '../../common/icons/DocumentIcon';
import EditIcon from '../../common/icons/EditIcon';
import ReloadIcon from '../../common/icons/ReloadIcon';

const DocLinkWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  background-color: #24242e;
  padding: 12px;
  border-radius: 12px;

  svg {
    flex-shrink: 0;
  }
`;

const DocLink = styled.a`
  margin-left: 12px;
  margin-right: 8px;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  background: #191a20;
  width: 32px;
  height: 32px;
  border-radius: 8px;

  :hover {
    transform: scale(1.05);
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const DocumentLink = ({ docLink, canEdit, setDocLink, initialLink, setDocument = undefined, extraValues = undefined }) => {
  const [edit, setEdit] = useState(false);

  const toogleEdit = () => {
    setEdit((prev) => !prev);
  };

  const saveDocument = (value) => {
    setDocLink(value);
    toogleEdit();
  };

  const saveFullDocument = (document) => {
    setDocument(document);
    toogleEdit();
  };

  const setInitialLink = () => {
    if (setDocument) {
      setDocument({
        document: initialLink,
        ...extraValues,
      });
      return;
    }

    setDocLink(initialLink);
  };

  return (
    <DocLinkWrapper>
      <DocumentIcon />
      <Tooltip key="doc-link" placement="top" title={docLink}>
        <DocLink target="_blank" href={docLink}>
          {docLink}
        </DocLink>
      </Tooltip>

      <Buttons>
        {initialLink !== docLink && (
          <Tooltip key="back-button" placement="top" title="Reset Changes">
            <EditButton onClick={setInitialLink}>
              <ReloadIcon />
            </EditButton>
          </Tooltip>
        )}

        {canEdit && (
          <Tooltip key="edit-button" placement="top" title="Edit url">
            <EditButton onClick={toogleEdit}>
              <EditIcon />
            </EditButton>
          </Tooltip>
        )}
      </Buttons>

      {edit && (
        <AttachDocumentModal
          initialValue={docLink}
          extraValues={extraValues}
          close={toogleEdit}
          saveDocument={saveDocument}
          saveFullDocument={saveFullDocument}
        />
      )}
    </DocLinkWrapper>
  );
};

export default DocumentLink;
