import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useAppSelector } from '../../store';
import Column from './column/Column';
import TaskPopupManager from './popup/TaskPopupManager';

const Wrapper = styled(motion.div)`
  padding-bottom: 100px;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media screen and (max-width: 1000px) {
    padding-bottom: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const Util = styled.div`
  width: 1px;
  flex-shrink: 0;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

function UserTaskBoard() {
  const auth = useAppSelector((state) => state.auth);
  const taskboard = useAppSelector((state) => state.taskboard);

  return (
    <>
      <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <ColumnsWrapper>
          {[
            { id: 'to-do', title: 'TO DO' },
            { id: 'in-revision', title: 'In revision' },
            { id: 'in-review', title: 'IN REVIEW' },
            { id: 'approved', title: 'Approved' },
          ].map((column) => {
            return (
              <Column
                type="user"
                auth={auth}
                key={column.id}
                title={column.title}
                columnId={column.id}
                tasks={taskboard.tasks}
              />
            );
          })}
        </ColumnsWrapper>
        <Util />
      </Wrapper>
      <AnimatePresence>
        <TaskPopupManager />
      </AnimatePresence>
    </>
  );
}

export default UserTaskBoard;
