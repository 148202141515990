import { useState } from 'react';
import styled from 'styled-components';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import Button from '@material-ui/core/Button';
import { AnimatePresence } from 'framer-motion';

import UserEditForm from './UserEditForm';
import UserEditPasswordForm from './UserEditPasswordForm';
import Modal from '../../common/modals/Modal';
import { User } from '../../../store/slices/usersSlice';
import { AuthState } from '../../../store/slices/authSlice';
import UserResetPasswordForm from './UserResetPasswordForm';
import EditIcon from '../../common/icons/EditIcon';

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

function UserEditProfile({ user, auth }: { user: User; auth: AuthState }) {
  const [profileForm, setProfileForm] = useState(false);
  const [passwordForm, setPasswordForm] = useState(false);
  const [resetPasswordForm, setResetPasswordForm] = useState(false);

  const openEditForm = () => {
    setProfileForm(true);
  };

  const closeEditForm = () => {
    setProfileForm(false);
  };

  const openPasswordForm = () => {
    setPasswordForm(true);
  };

  const closePasswordForm = () => {
    setPasswordForm(false);
  };

  const openResetPasswordForm = () => {
    setResetPasswordForm(true);
  };

  const closeResetPasswordForm = () => {
    setResetPasswordForm(false);
  };

  const renderPopup = () => {
    if (profileForm) {
      return (
        <Modal>
          <UserEditForm user={user} onClose={closeEditForm} />
        </Modal>
      );
    }
    if (passwordForm) {
      return (
        <Modal>
          <UserEditPasswordForm user={user} onClose={closePasswordForm} />
        </Modal>
      );
    }
    if (resetPasswordForm) {
      return (
        <Modal>
          <UserResetPasswordForm user={user} onClose={closeResetPasswordForm} />
        </Modal>
      );
    }
  };

  return (
    <div>
      {!user.isDeleted && (
        <StyledButton
          color="secondary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={openEditForm}
          startIcon={<EditIcon fill="#cecece" />}
        >
          Edit profile
        </StyledButton>
      )}
      {!user.isDeleted && auth.isAdmin && auth._id !== user._id && (
        <StyledButton
          color="secondary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={openResetPasswordForm}
          startIcon={
            <VpnKeyRoundedIcon fontSize="small" style={{ color: '#cecece' }} />
          }
        >
          Reset password
        </StyledButton>
      )}
      {!user.isDeleted && user._id === auth._id && (
        <StyledButton
          color="secondary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={openPasswordForm}
          startIcon={
            <VpnKeyRoundedIcon fontSize="small" style={{ color: '#cecece' }} />
          }
        >
          Change password
        </StyledButton>
      )}
      <AnimatePresence>{renderPopup()}</AnimatePresence>
    </div>
  );
}

export default UserEditProfile;
