import SearchIcon from './icons/SearchIcon';
import CloseIcon from './icons/CloseIcon';
import styled from 'styled-components';
import { Dispatch, FC, SetStateAction } from 'react';

const Field = styled.input`
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 12px;
  flex-grow: 1;
  outline: none;
  background-color: transparent;

  ::placeholder {
    color: #545970;
  }
`;

const IconButton = styled.button`
  display: inline-flex;
  flex-shrink: 0;
  cursor: pointer;
`;

const containerStyle = `
  width: 100%;
  display: flex;
  align-items: center;
`;

const generalContainerStyle = `
 ${containerStyle}
  background: #2a2d38;
  border-radius: 16px;
  padding: 18px;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 8px;
  }
`;

const Container = styled.div<{ customWrapper?: boolean }>`
  ${({ customWrapper }) =>
    customWrapper ? containerStyle : generalContainerStyle}
`;

type SearchInput = {
  value: string;
  placeholder: string;
  customWrapper?: boolean;
  onChange: Dispatch<SetStateAction<string>>;
};

export const SearchInput: FC<SearchInput> = ({
  value,
  placeholder,
  customWrapper,
  onChange,
}) => {
  const handleChange = (e) => onChange(e.target.value);

  return (
    <Container customWrapper={customWrapper}>
      <SearchIcon />
      <Field placeholder={placeholder} value={value} onChange={handleChange} />
      {value.length > 0 && (
        <IconButton
          type="button"
          onClick={() => {
            onChange('');
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Container>
  );
};
