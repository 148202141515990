import styled from 'styled-components';
import { useAppDispatch } from '../../../store';
import { restoreTaskAsync } from '../../../store/slices/taskboardSlice';

const Wrapper = styled.div`
  padding-top: 24px;
`;

const Button = styled.button`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  background-color: #d69f4c;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  cursor: pointer;
  line-height: 1.2;
  text-transform: uppercase;
  transition: all 0.2s ease;
  transition: all 0.2s ease;

  :hover {
    background-color: #a87c3b;
  }

  :active {
    transform: scale(0.98);
  }

  svg {
    margin-left: 6px;
  }
`;

function PendingActions({ taskId, isAdmin }) {
  const dispatch = useAppDispatch();
  const handleRestore = () => {
    dispatch(restoreTaskAsync({ taskId }));
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <Wrapper>
      <Button onClick={handleRestore}>Stop pending</Button>
    </Wrapper>
  );
}

export default PendingActions;
