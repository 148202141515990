import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Modal } from '../../../../store/slices/uiSlice';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import FinalStep from './FinalStep';

const Wrapper = styled(motion.div)`
  width: 450px;
  background: #626275;
  padding: 48px 32px 32px 32px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const IconButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

interface CreateUserModalProps {
  modal: Modal;
  handleClose: () => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  modal,
  handleClose,
}) => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    creditCard: '',
    password: '',
    telegramLogin: '',
    isAdmin: false,
  });

  const handleStepSubmit = (stepValues) => {
    setValues((prev) => ({ ...prev, ...stepValues }));
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <StepOne
              initialValues={values}
              handleStepSubmit={handleStepSubmit}
            />
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <StepTwo
              values={values}
              handleBack={handleBack}
              handleStepSubmit={handleStepSubmit}
            />
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <StepThree
              values={values}
              handleBack={handleBack}
              handleStepSubmit={handleStepSubmit}
            />
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <FinalStep values={values} handleClose={handleClose} />
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <AnimateSharedLayout>
      <Wrapper layout>
        <AnimatePresence initial={false} exitBeforeEnter>
          {renderStep()}
        </AnimatePresence>
        <IconButton onClick={handleClose}>
          <CloseRoundedIcon style={{ color: '#979797' }} />
        </IconButton>
      </Wrapper>
    </AnimateSharedLayout>
  );
};

export default CreateUserModal;
