import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextareaAutosize from 'react-textarea-autosize';
import { motion } from 'framer-motion';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../../lib/services/images';
import { useAppDispatch } from '../../../../store';
import { createCommentAsync } from '../../../../store/slices/taskboardSlice';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
`;
const FormWrapper = styled.div`
  flex-grow: 1;
`;

const Form = styled.form`
  padding: 12px;
  border-radius: 8px;
  background-color: #24242e;
`;
const Message = styled(TextareaAutosize)`
  width: 100%;
  outline: none;
  margin-bottom: 1rem;
  font-family: inherit;
  padding: 0;
  font-size: 16px;
  display: block;
  outline: none;
  line-height: 20px;
  caret-color: var(--primary);
  background-color: #24242e;
  color: #d3d3d3;
  font-weight: 500;
  height: 20px;
  min-height: 20px;
  overflow: hidden;
  resize: none;
  margin: 0;
  overflow-wrap: break-word;
  -webkit-appearance: none;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`;
const Actions = styled(motion.div)`
  padding-top: 12px;
  button:first-child {
    margin-right: 8px;
  }
`;

function NewComment({ auth, task }) {
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const dispatch = useAppDispatch();

  const openForm = () => {
    setFocus(true);
  };

  const closeForm = () => {
    setValue('');
    setFocus(false);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValue(e.target.value);
    await dispatch(createCommentAsync({ taskId: task._id, text: value }));
    closeForm();
  };

  return (
    <Wrapper>
      <Avatar src={auth.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <Message
            value={value}
            placeholder="Write your comment..."
            onClick={openForm}
            onFocus={openForm}
            required
            onChange={handleChange}
          />
          {focus && (
            <Actions initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
              >
                Send
              </Button>
              <Button
                type="button"
                size="small"
                variant="text"
                color="primary"
                onClick={closeForm}
                disableElevation
              >
                Cancel
              </Button>
            </Actions>
          )}
        </Form>
      </FormWrapper>
    </Wrapper>
  );
}

export default NewComment;
