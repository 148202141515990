import { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from '../../../lib/api/axios';
import PasswordField from '../../common/forms/PasswordField';

const Content = styled.div`
  padding: 28px;
  border-radius: 20px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
`;

const Form = styled.form`
  min-width: 350px;
  > div {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 600px) {
    min-width: 300px;
  }
`;

const Actions = styled.div<{ isSuccess: boolean }>`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
  display: ${({ isSuccess }) => (isSuccess ? 'none' : 'flex')};
`;

const StyledButton = styled(Button)`
  width: 100px;

  :first-of-type {
    margin-right: 16px;
  }
`;

const Success = styled(motion.p)`
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #1f877f;
  border-radius: 8px;

  span {
    font-size: 24px;
    margin-left: 8px;
  }
`;

function UserEditPasswordForm({ user, onClose }) {
  const [fields, setFields] = useState({
    oldPassword: { value: '', error: '' },
    newPassword: { value: '', error: '' },
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { oldPassword, newPassword } = fields;
    setLoading(true);
    const payload = {
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
    };
    try {
      await axios({
        method: 'put',
        url: `/user/password/change`,
        data: payload,
      });
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        onClose();
      }, 1200);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (target) => {
    setFields((state) => {
      const previous = state[target.name];
      return { ...state, [target.name]: { ...previous, value: target.value } };
    });
  };

  return (
    <Content>
      <Form onSubmit={handleSubmit}>
        <PasswordField
          label="Old password"
          name="oldPassword"
          onChange={handleChange}
          inputProps={{
            autoComplete: 'new-password',
            autoFocus: true,
          }}
        />
        <PasswordField
          label="New password"
          name="newPassword"
          onChange={handleChange}
          inputProps={{
            autoComplete: 'current-password',
            autoFocus: false,
          }}
        />
        <Actions isSuccess={success}>
          <StyledButton
            type="button"
            color="primary"
            disableElevation
            variant="text"
            onClick={onClose}
            disabled={loading || success}
          >
            Cancel
          </StyledButton>
          <StyledButton
            type="submit"
            color="primary"
            disableElevation
            variant="contained"
            disabled={loading || success}
            endIcon={
              loading && (
                <CircularProgress size={20} style={{ color: '#fff' }} />
              )
            }
          >
            {loading ? 'Saving' : 'Save'}
          </StyledButton>
        </Actions>
        {success && (
          <Success
            initial={{ y: -5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
          >
            Password has been changed <span>👌</span>
          </Success>
        )}
      </Form>
    </Content>
  );
}

export default UserEditPasswordForm;
