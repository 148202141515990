import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Redirect, useRouteMatch } from 'react-router-dom';
import UserInfo from './UserInfo';
import UserTasks from './UserTasks';
import UserCardWidget from './card-widget/UserCardWidget';
import UserProfileInfo from './UserProfileInfo';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchUserTasksAsync } from '../../store/slices/taskboardSlice';

const Wrapper = styled.div``;
const Container = styled.div`
  display: flex;
  padding-bottom: 64px;

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    > div {
      width: 100%;
    }
  }
`;
const LeftSide = styled.div`
  width: 70%;

  @media screen and (max-width: 1360px) {
    width: 50%;
    margin-bottom: 24px;
  }
`;
const RightSide = styled.div`
  width: 30%;
  align-self: flex-start;

  @media screen and (max-width: 960px) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 1360px) {
    width: 50%;
  }
`;

function UserBoard() {
  const auth = useAppSelector((state) => state.auth);
  const match = useRouteMatch<{ id: string }>();
  const userId = match.params.id;
  const users = useAppSelector((state) => state.users);
  const fetchDispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const iFrame = document.getElementById(
      'telegram-login-PlantIn_expert_help_test_bot'
    );

    if (iFrame) {
      iFrame.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    if (auth._id) {
      fetchDispatch(fetchUserTasksAsync({executor: userId}))
        .then();
    }
  }, [userId, auth, fetchDispatch]);

  if (!auth) {
    return <Redirect to="/login" />;
  }

  const user = users.find((u) => u._id === userId);

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <UserInfo user={user} />
      <Container>
        <LeftSide>
          <UserTasks user={user} />
        </LeftSide>
        <RightSide>
          <UserProfileInfo user={user} auth={auth} />
          {!user.isAdmin && <UserCardWidget user={user} />}
        </RightSide>
      </Container>
    </Wrapper>
  );
}

export default UserBoard;
