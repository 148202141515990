import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { saveAs } from 'file-saver';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { User } from '../../store/slices/usersSlice';
import { useAppSelector } from '../../store';
import { FilterState } from './types';
import axios from '../../lib/api/axios';
import { UserSelector } from './UserSelector';
import { FetchArchiveTasksPayload } from '../../store/slices/taskboardSlice';

const Wrapper = styled.nav`
  display: flex;
  margin-bottom: 24px;
  gap: 16px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiInputBase-root {
    color: var(--white);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: var(--primary);
  }

  .MuiIconButton-root {
    color: var(--white);
  }
`;

const DatePickersWrapper = styled.div`
  display: flex;
  margin-left: auto;

  > div:first-child {
    margin-right: 16px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-top: 24px;
    margin-left: 0;

    > div:first-child {
      margin-bottom: 16px;
      margin-right: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  &[disabled] {
    color: #fff;
    background-color: #b9375e;
    cursor: default;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -o-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -moz-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -webkit-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
  }
  @-o-keyframes pulse {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
  }
  @-moz-keyframes pulse {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
  }
  @-webkit-keyframes pulse {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.7;
    }
  }
`;

interface FilterBarProps {
  users: User[];
  filterState: FetchArchiveTasksPayload;
  handleFiltersChange: (filter: {
    name: string;
    value: string | number | null;
  }) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  users,
  filterState,
  handleFiltersChange,
}) => {
  const { startTime, endTime, executor, proofreader } = filterState;
  const selectedAuthor = users?.find((u) => u._id === executor);
  const selectedProofreader = users?.find((u) => u._id === proofreader);
  const [fromDate, handleFromDateChange] = useState(
    new Date(Number(startTime))
  );
  const [toDate, handleToDateChange] = useState(
    new Date(Number(endTime) - 86400000)
  );

  const handleAuthorSelect = (user) => () => {
    handleFiltersChange({ name: 'executor', value: user ? user._id : null });
  };

  const handleProofreaderSelect = (user) => () => {
    handleFiltersChange({ name: 'proofreader', value: user ? user._id : null });
  };

  const auth = useAppSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const handleStartDate = (date) => {
    const startTime = new Date(
      date.replace(/\//g, '-').split('-').reverse().join('-')
    ).getTime();
    handleFiltersChange({ name: 'startTime', value: startTime });
  };

  const handleEndDate = (date) => {
    const timestamp = new Date(
      date.replace(/\//g, '-').split('-').reverse().join('-')
    ).getTime();
    const endTime = timestamp + 86400000;
    handleFiltersChange({ name: 'endTime', value: endTime });
  };

  const getDownloadFile = async () => {
    return axios.get(
      `/task/export-archive?from=${fromDate.getTime()}&to=${toDate.getTime()}${
        selectedAuthor?._id ? `&user=${selectedAuthor._id}` : ''
      }`,
      {
        responseType: 'blob',
      }
    );
  };

  const downloadFile = () => {
    setLoading(true);

    getDownloadFile()
      .then((response) => saveAs(response.data, 'archive.xlsx'))
      .finally(() => setLoading(false));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        {auth.isAdmin && (
          <>
            <UserSelector
              name="Author"
              users={users}
              selectedUser={selectedAuthor}
              handleSelect={handleAuthorSelect}
            />
            <UserSelector
              name="Proofreader"
              users={users}
              selectedUser={selectedProofreader}
              handleSelect={handleProofreaderSelect}
            />
          </>
        )}

        <DatePickersWrapper>
          <StyledKeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="From"
            format="dd/MM/yyyy"
            value={fromDate}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date, value) => {
              handleStartDate(value);
              handleFromDateChange(date);
            }}
          />
          <StyledKeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="To"
            format="dd/MM/yyyy"
            value={toDate}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date, value) => {
              handleEndDate(value);
              handleToDateChange(date);
            }}
          />
        </DatePickersWrapper>

        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          onClick={downloadFile}
          disabled={loading}
        >
          Download
        </StyledButton>
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

export default FilterBar;
