/* eslint-disable consistent-return */
import { io } from 'socket.io-client';
// eslint-disable-next-line import/no-cycle
import {
  createNewTask,
  attachDocumentViaSocket,
  assignTasktViaSocket,
  assignProofReaderViaSocket,
  approveTasktViaSocket,
  setOnRevisionViaSocket,
  pendTaskViaSocket,
  unpendTaskViaSocket,
} from '../slices/taskboardSlice';

type WsConnectAction = (host?: string) => { type: 'WS_CONNECT'; host?: string };

export const wsConnect: WsConnectAction = (host?: string) => ({
  type: 'WS_CONNECT',
  host,
});

const socketMiddleware = () => {
  let socket = null;

  return (store) => (next) => (action) => {
    switch (action.type) {
      case 'WS_CONNECT':
        socket = io(action.host);

        socket.on('connect', () => {
          console.log('WebSocket connected');
        });

        socket.on('disconnect', () => {
          console.log('WebSocket disconnected');
        });

        socket.on('task', (payload) => {
          store.dispatch(createNewTask(payload));
        });

        socket.on('assign', (payload) => {
          store.dispatch(assignTasktViaSocket(payload));
        });

        socket.on('proofread', (payload) => {
          store.dispatch(assignProofReaderViaSocket(payload));
        });

        socket.on('appr', (payload) => {
          if (store.getState().auth._id === payload.executor._id) {
            store.dispatch(approveTasktViaSocket(payload));
          }
        });

        socket.on('revision', (payload) => {
          if (store.getState().auth._id === payload.executor._id) {
            store.dispatch(setOnRevisionViaSocket(payload));
          }
        });

        socket.on('attachment', (payload) => {
          if (store.getState().auth.isAdmin) {
            store.dispatch(attachDocumentViaSocket(payload));
          }
        });

        socket.on('pend-task', (payload) => {
          if (store.getState().auth._id === payload.author) {
            store.dispatch(pendTaskViaSocket({ taskId: payload.taskId }));
          }
        });

        socket.on('unpend-task', (payload) => {
          if (store.getState().auth._id === payload.author) {
            store.dispatch(unpendTaskViaSocket({ taskId: payload.taskId }));
          }
        });

        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
